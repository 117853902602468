import { Col, Empty, Row } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import LoadingSpinner from '../../../../shared/components/Spinner'
import { ObjectLiteral } from '../../../../shared/interfaces/object'
import { getCurrencyFormat } from '../../../../shared/utils/intl'
import { ChartStoreInstance } from '../../stores/chart'

export interface ListingSummaryProps {
  store: ChartStoreInstance
}

const ListingSummary: React.FC<ListingSummaryProps> = observer((props: ListingSummaryProps) => {
  const intl = useIntl()
  const { store } = props

  useEffect(() => {
    store.api_getData()
  }, [])

  const dataSource = (): ObjectLiteral[] | undefined =>
    store.payload
      ? JSON.parse(store.payload).map((data: ObjectLiteral) => {
          return {
            de_listed_date: data.de_listed_date ? intl.formatDate(data.de_listed_date, { timeZone: 'UTC' }) : '',
            sum_listed: data.sum_listed,
            sum_paid: data.sum_paid
          }
        })
      : undefined

  return store.meta.request.loading ? (
    <LoadingSpinner />
  ) : (dataSource() as any)?.length > 0 ? (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
        <ResponsiveContainer>
          <BarChart data={dataSource()}>
            <CartesianGrid strokeDasharray={'3 3'} />
            <XAxis dataKey={'de_listed_date'} />
            <YAxis
              tickFormatter={(value) => intl.formatNumber(value ?? 0, getCurrencyFormat({ notation: 'compact' }))}
              width={40}
            />
            <Tooltip
              formatter={(value: any, name: any, item: any) => {
                switch (item.dataKey) {
                  case 'sum_listed':
                  case 'sum_paid': {
                    return intl.formatNumber(value, getCurrencyFormat())
                  }
                  default:
                    return value
                }
              }}
            />
            <Legend />
            <Bar dataKey={'sum_listed'} name={'Listed'} fill={'#F89939'} />
            <Bar dataKey={'sum_paid'} name={'Paid'} fill={'#2BA3BB'} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  ) : (
    <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
      <Col>
        <Empty />
      </Col>
    </Row>
  )
})

export default ListingSummary

import { Provider as MobxProvider } from 'mobx-react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import AppContainer from './App'
import reportWebVitals from './reportWebVitals'
import { history, RouterStore } from './shared/stores/router'

ReactDOM.render(
  <MobxProvider store={RouterStore}>
    <Router history={history}>
      <AppContainer />
    </Router>
  </MobxProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

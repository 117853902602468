import { Layout, Typography } from 'antd'
import { observer } from 'mobx-react'
import React, { Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { lazyPreload } from '../../shared/components/PreloadLink/utils'
import Spinner from '../../shared/components/Spinner'
import { ObjectLiteral } from '../../shared/interfaces/object'
import { RouterStore } from '../../shared/stores/router'
import { AuthStore } from '../auth/stores'
import DashboardContainer from '../dashboard'
import IdleTimer from './components/IdleTimer'
import CoreMenu from './components/Menu'
import RefreshTokenTimer from './components/RefreshTokenTimer'

const AccountContainer = lazyPreload(() => import('../account'))
const AuditLogContainer = lazyPreload(() => import('../audit-log'))
const ComplianceContainer = lazyPreload(() => import('../compliance'))
const ReportContainer = lazyPreload(() => import('../report'))

const CoreContainer: React.FC = observer(() => {
  const { Content, Footer, Header } = Layout
  const { Text } = Typography

  const routes: ObjectLiteral[] = [
    { path: '/account', exact: false, component: AccountContainer },
    { path: '/dashboards', exact: false, component: DashboardContainer },
    { path: '/reports', exact: false, component: ReportContainer },
    ...(AuthStore.isManager ? [{ path: '/audit-logs', exact: false, component: AuditLogContainer }] : []),
    ...(AuthStore.isManager || AuthStore.isOperator
      ? [{ path: '/compliance', exact: false, component: ComplianceContainer }]
      : [])
  ]

  return (
    <Layout style={{ backgroundColor: '#FFF', display: 'flex', flexDirection: 'column', height: '100%' }}>
      <IdleTimer warningTimeout={36e5 * 7.5} logoutTimeout={36e5 * 8} />
      <RefreshTokenTimer />
      <Header style={{ background: '#FFF', borderBottom: '1px solid #F0F2F5' }}>
        <CoreMenu routes={routes} />
      </Header>
      <Content style={{ flex: '1 0 auto', padding: '16px 50px' }}>
        <Suspense fallback={<Spinner />}>
          {routes.map((route) => (
            <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
          ))}
        </Suspense>
        {RouterStore.router.location.pathname === '/' && <Redirect exact from={'/'} to={'/dashboards'} />}
      </Content>
      <Footer style={{ flexShrink: 0, textAlign: 'center' }}>
        <Text style={{ fontSize: '8pt' }}>
          &copy; 2021 Comtech Systems Inc. All Rights Reserved &bull; Use of the Collect! system indicates acceptance of
          our product terms &bull;{' '}
          <a href={'https://www.collect.org/legal.html'} rel="noopener noreferrer" target={'_blank'}>
            Legal Notices
          </a>
        </Text>
      </Footer>
    </Layout>
  )
})

export default CoreContainer

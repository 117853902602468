import querystring from 'querystring'
import React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'

interface AuthRouteProps extends RouteProps {
  authenticated: boolean
}

export const AuthRoute: React.FC<AuthRouteProps> = ({ authenticated, component, ...rest }: AuthRouteProps) => {
  if (!component) throw new Error('Component is undefined')

  const render = (props: RouteComponentProps) => {
    const Component = component

    if (authenticated) {
      return <Component {...props} />
    } else {
      const next = rest.location?.pathname?.indexOf('logout') === -1 ? rest.location.pathname : undefined
      const qs = next && next !== '/' ? querystring.stringify({ next: next }) : undefined

      return <Redirect to={`/auth/login${qs ? `?${qs}` : ``}`} />
    }
  }

  return <Route render={render} {...rest} />
}

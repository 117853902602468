import { createRequestUrl } from '../../../../shared/utils/request'
import { CollectApiClient } from './client'

const getCompanyDetails = () =>
  CollectApiClient({
    method: 'GET',
    url: createRequestUrl('/api/v1/dashboard/settings/company-details')
  })

export const NxUserApiService = {
  getCompanyDetails
}

import querystring from 'query-string'
import { ObjectLiteral } from '../interfaces/object'
import { PaginatedRequestParams, RequestParams } from '../interfaces/request'

export const filterStringToParts = (filter?: string) => {
  let filterStringArray: string[] = []

  if (filter) {
    const cleanFilterString = filter.split(/\[|\]/).filter((el) => el !== '')[0]
    filterStringArray = cleanFilterString.split(/,(?![^\(]*[\)])/) // Split on comma unless surrounded by ()
  }

  return filterStringArray
}

export const createRequestUrl = (path: string, params?: RequestParams, extra?: ObjectLiteral) => {
  const query = {
    ...(params && {
      ...(params.filter && { filter: params.filter }),
      ...(params.search && { search: params.search }),
      ...(params.sort && { sort: params.sort })
    }),
    ...extra
  }

  return querystring.stringifyUrl(
    {
      url: path,
      query: query
    },
    { encode: false }
  )
}

export const createPaginatedRequestUrl = (path: string, params?: PaginatedRequestParams, extra?: ObjectLiteral) => {
  const query = {
    ...(params && {
      ...(params.limit && { limit: String(params.limit) }),
      ...(params.page && { page: String(params.page) }),
      ...(params.filter && { filter: params.filter }),
      ...(params.search && { search: params.search }),
      ...(params.sort && { sort: params.sort })
    }),
    ...extra
  }

  return querystring.stringifyUrl(
    {
      url: path,
      query: query
    },
    { encode: false }
  )
}

import { castToSnapshot, Instance, types } from 'mobx-state-tree'
import { CreateMetaModelProps } from '../../../shared/stores/meta'
import { createNotificationStore, NotificationStore } from '../components/notifications/stores'

const BaseAccountStore = types.model({
  notifications: NotificationStore
})

export interface AccountStoreInstance extends Instance<typeof BaseAccountStore> {}

interface CreateAccountStoreProps {
  notifications: {
    meta: CreateMetaModelProps
  }
}

const createAccountStore = (props?: CreateAccountStoreProps) => {
  return BaseAccountStore.create({
    notifications: castToSnapshot(createNotificationStore(props?.notifications))
  })
}

const AccountStore = createAccountStore()

export default AccountStore

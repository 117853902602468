import { getCurrency } from 'locale-currency'
import { FormatNumberOptions } from 'react-intl'

export const getCurrencyFormat = (options?: FormatNumberOptions): FormatNumberOptions => {
  const opts = () => {
    const opts = options
    if (opts?.notation && opts.notation === 'compact') {
      opts.minimumFractionDigits = 0
    }

    return opts
  }

  return {
    currency: getCurrency(navigator.language),
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...opts()
  }
}

export const getDateFormatString = () => {
  // Source: https://stackoverflow.com/a/56887627

  const dateParts = new Intl.DateTimeFormat(navigator.language).formatToParts(new Date())

  return dateParts
    .map((part) => {
      switch (part.type) {
        case 'day':
          return 'DD'
        case 'month':
          return 'MM'
        case 'year':
          return 'YYYY'
        default:
          return part.value
      }
    })
    .join('')
}

export const getNumberFormat = (options?: FormatNumberOptions): FormatNumberOptions => {
  return {
    notation: 'compact',
    ...options
  }
}

import { Col, Empty, Row } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { useIntl } from 'react-intl'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import Spinner from '../../../../../shared/components/Spinner'
import { ObjectLiteral } from '../../../../../shared/interfaces/object'
import { getCurrencyFormat } from '../../../../../shared/utils/intl'
import { ChartStoreInstance } from '../../../stores/chart'

interface TodayPaymentTotalsProps {
  store: ChartStoreInstance
}

const TodayPaymentTotals: React.FC<TodayPaymentTotalsProps> = observer((props: TodayPaymentTotalsProps) => {
  const intl = useIntl()
  const { store } = props

  const dataSource = (): ObjectLiteral[] => {
    const arr: ObjectLiteral[] = []

    if (store.payload) {
      const data: ObjectLiteral[] = JSON.parse(store.payload)

      data.forEach((d) => {
        const { sum_collected, sum_commission } = d

        if (sum_collected !== 0 || sum_commission !== 0) {
          arr.push({ name: 'Collected', value: sum_collected })
          arr.push({ name: 'Commission', value: sum_commission })
        }
      })
    }

    return arr
  }

  const colors = ['#F89939', '#2BA3BB']

  return store.meta.request.loading ? (
    <Spinner active={store.meta.request.loading} />
  ) : dataSource()?.length > 0 ? (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
        <ResponsiveContainer>
          <PieChart>
            <Tooltip
              formatter={(value: any, name: any, item: any) => {
                switch (item.dataKey) {
                  case 'value': {
                    return intl.formatNumber(value ?? 0, getCurrencyFormat({ notation: 'compact' }))
                  }
                  default:
                    return value
                }
              }}
            />
            <Legend />
            <Pie data={dataSource()} dataKey={'value'}>
              {dataSource()?.map?.((entry: ObjectLiteral, index: number) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  ) : (
    <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
      <Col>
        <Empty />
      </Col>
    </Row>
  )
})

export default TodayPaymentTotals

import { Typography } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { getCurrencyFormat } from '../../../../shared/utils/intl'
import { ChartStoreInstance } from '../../stores/chart'

interface OverallSummaryProps {
  label?: string
  store: ChartStoreInstance
  type: 'client' | 'manager' | 'operator'
}

const OverallSummary: React.FC<OverallSummaryProps> = observer((props: OverallSummaryProps) => {
  const intl = useIntl()
  const { store } = props
  const jsonData = store.payload ? JSON.parse(store.payload) : undefined

  useEffect(() => {
    store.api_getData()
  }, [])

  const { Text } = Typography

  const renderClientTable = () => {
    return (
      <table
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              <Text strong>{props.label}</Text>
            </th>
            <th style={{ textAlign: 'left' }}>
              <Text strong>Collected</Text>
            </th>
            <th style={{ textAlign: 'left' }}>
              <Text strong>Commission</Text>
            </th>
            <th style={{ textAlign: 'left' }}>
              <Text strong>Return</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <Text strong>Yesterday:</Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['yesterday_sum_collected'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['yesterday_sum_commission'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['yesterday_sum_return'] ?? 0, getCurrencyFormat())}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <Text strong>Today:</Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['today_sum_collected'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['today_sum_commission'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['today_sum_return'] ?? 0, getCurrencyFormat())}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <Text strong>Month-to-Date:</Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['month_to_date_sum_collected'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['month_to_date_sum_commission'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['month_to_date_sum_return'] ?? 0, getCurrencyFormat())}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  const renderManagementOperatorTable = () => {
    return (
      <table
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              <Text ellipsis strong>
                {props.label}
              </Text>
            </th>
            <th style={{ textAlign: 'right' }}>
              <Text ellipsis strong>
                Collected
              </Text>
            </th>
            <th style={{ textAlign: 'right' }}>
              <Text ellipsis strong>
                Commission
              </Text>
            </th>
            <th style={{ textAlign: 'right' }}>
              <Text ellipsis strong>
                Target
              </Text>
            </th>
            <th style={{ textAlign: 'right' }}>
              <Text ellipsis strong>
                Target %
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <Text strong>Yesterday:</Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['yesterday_sum_collected'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['yesterday_sum_commission'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['yesterday_target'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData &&
                intl.formatNumber(jsonData['yesterday_target_pct'] ?? 0, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'percent'
                })}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <Text strong>Today:</Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['today_sum_collected'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['today_sum_commission'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['today_target'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData &&
                intl.formatNumber(jsonData['today_target_pct'] ?? 0, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'percent'
                })}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: 'right' }}>
              <Text strong>Month-to-Date:</Text>
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['month_to_date_sum_collected'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['month_to_date_sum_commission'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData && intl.formatNumber(jsonData['month_to_date_target'] ?? 0, getCurrencyFormat())}
            </td>
            <td style={{ textAlign: 'right' }}>
              {jsonData &&
                intl.formatNumber(jsonData['month_to_date_target_pct'] ?? 0, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'percent'
                })}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
        {props.type === 'client' ? renderClientTable() : renderManagementOperatorTable()}
      </div>
    </div>
  )
})

export default OverallSummary

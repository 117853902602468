import jsonwebtoken from 'jsonwebtoken'
import { observer } from 'mobx-react'
import querystring from 'query-string'
import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { IntlProvider } from 'react-intl'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import AuthContainer from './modules/auth'
import { AuthRoute } from './modules/auth/components/AuthRoute'
import { AuthStore } from './modules/auth/stores'
import { NxUserStore } from './modules/collect/stores/nx-user'
import CoreContainer from './modules/core'
import { CollectToastContainer } from './shared/components/ToastContainer'
import { ObjectLiteral } from './shared/interfaces/object'
import { cookies } from './shared/utils/cookie'

const AppContainer: React.FC = observer(() => {
  const [accessToken, setAccessToken] = useState(cookies.get('x-collect-access'))
  const [refreshToken, setRefreshToken] = useState(cookies.get('x-collect-refresh'))

  const location = useLocation()
  const matchLoginRoute = useRouteMatch('/auth/login')
  const nextLocation: string = location.search
    ? (querystring.parse(location.search.replace('?', '')).next as string)
    : '/'

  useEffect(() => {
    const { authenticated, accessToken, refreshToken } = AuthStore.meta

    if (authenticated && accessToken && refreshToken) {
      AuthStore.api_getProfile()
      NxUserStore.api_getCompanyDetails()
    } else {
      setAccessToken(setAccessToken)
      setRefreshToken(setRefreshToken)
    }
  }, [])

  if (accessToken) {
    const payload: ObjectLiteral | string | null = jsonwebtoken.decode(accessToken)
    if (payload instanceof Object) {
      AuthStore.meta.setAuthenticated(true)
      AuthStore.meta.setAccessToken(accessToken)
    }
  }
  if (refreshToken) {
    const payload: ObjectLiteral | string | null = jsonwebtoken.decode(refreshToken)
    if (payload instanceof Object) {
      AuthStore.meta.setRefreshToken(refreshToken)
    }
  }

  return (
    <IntlProvider locale={navigator.language}>
      {matchLoginRoute && AuthStore.meta.authenticated && <Redirect to={nextLocation} />}
      <HelmetProvider>
        <Helmet defaultTitle={'Collect! Dashboard'} titleTemplate={'%s | Collect! Dashboard'} />
        <ToastProvider components={{ ToastContainer: CollectToastContainer }}>
          <Switch>
            <Route path={'/auth'} component={AuthContainer} />
            <AuthRoute authenticated={AuthStore.meta.authenticated} path={'/'} component={CoreContainer} />
          </Switch>
        </ToastProvider>
      </HelmetProvider>
    </IntlProvider>
  )
})

export default AppContainer

import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Popover, Row, Space, Typography } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { observer } from 'mobx-react'
import React from 'react'
import { FilterBarStoreInstance } from '../../stores'

interface CheckboxFilterProps {
  filterBarStore: FilterBarStoreInstance
  label: string
  name: string
  options: { label: string; value: string }[]
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = observer((props: CheckboxFilterProps) => {
  const { Text } = Typography

  const filterValuesExist = props.filterBarStore.filters.get(`${props.name}__in`)?.value

  return (
    <Popover
      content={
        <Space direction={'vertical'} size={'small'}>
          <Row>
            <Col flex={1}>
              <Text strong>{props.label}</Text>
            </Col>
            <Col flex={0}>
              {filterValuesExist && (
                <Button
                  onClick={() => {
                    props.filterBarStore.addFilter({ field: props.name, operation: 'in', value: undefined })
                  }}
                  size={'small'}
                  type={'link'}
                >
                  <CloseOutlined /> Clear
                </Button>
              )}
            </Col>
          </Row>
          <Checkbox.Group
            onChange={(checkedValue: Array<CheckboxValueType>) => {
              props.filterBarStore.addFilter({
                field: props.name,
                operation: 'in',
                value: checkedValue.length > 0 ? checkedValue.join(',') : undefined
              })
            }}
            options={props.options}
            value={filterValuesExist?.split(',')}
          >
            {props.options.map(
              (option: { label: string; value: string }, index: number, arr: { label: string; value: string }[]) => {
                const { label, value } = option

                return (
                  <React.Fragment key={value}>
                    <Checkbox value={value}>
                      <Text ellipsis>{label}</Text>
                    </Checkbox>
                    {index < arr.length - 1 && <br />}
                  </React.Fragment>
                )
              }
            )}
          </Checkbox.Group>
        </Space>
      }
      placement={'bottomLeft'}
      trigger={'click'}
    >
      <Button data-cy={`filter-bar-filters-checkbox-${props.name}`} type={filterValuesExist ? 'primary' : 'default'}>
        <span>
          <Text style={{ color: filterValuesExist ? '#F2F2F2' : '#8C8C8C' }}>{props.label}: </Text>
          <Text strong style={{ color: filterValuesExist ? '#F2F2F2' : '#000' }}>
            {filterValuesExist
              ? `${props.filterBarStore.filters?.get(`${props.name}__in`)?.value?.split(',').length} Values`
              : 'All'}
          </Text>
        </span>
        <DownOutlined />
      </Button>
    </Popover>
  )
})

export default CheckboxFilter

import React from 'react'
import { DefaultToastContainer } from 'react-toast-notifications'

export const CollectToastContainer: React.FC = (props: any) => {
  return (
    <DefaultToastContainer
      className={'toast-container'}
      style={{
        zIndex: 9000
      }}
      {...props}
    />
  )
}

import { Space } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Helmet } from 'react-helmet-async'
import FilterBar from '../../../../shared/components/FilterBar'
import CollectPageHeader from '../../../../shared/components/PageHeader'
import { AuthStore } from '../../../auth/stores'
import { createChartStore } from '../../stores/chart'
import CollectionsHistory from '../charts/CollectionsHistory'
import ContactSummary from '../charts/ContactSummary'
import OverallSummary from '../charts/OverallSummary'
import Widget from '../shared/Widget'

const RGL = WidthProvider(Responsive)

const OperatorDashboard: React.FC = observer(() => {
  const [contactSummaryOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/operator/contact-summary` } }
    })
  })

  const [fiveDayCollectionsByOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/operator/five-day-collections` } }
    })
  })

  const [overallSummaryOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/operator/overall-summary` } }
    })
  })

  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }
  const cols = { lg: 6, md: 6, sm: 6, xs: 3, xxs: 3 }
  const layouts = {
    lg: [
      { i: 'operator-cctr', x: 0, y: 0, w: 6, h: 1, static: true },
      { i: 'operator-contacts', x: 0, y: 1, w: 2, h: 2, static: true },
      { i: 'operator-collections', x: 2, y: 1, w: 4, h: 2, static: true }
    ],
    xs: [
      { i: 'operator-cctr', x: 0, y: 0, w: 3, h: 1, static: true },
      { i: 'operator-contacts', x: 0, y: 1, w: 3, h: 2, static: true },
      { i: 'operator-collections', x: 0, y: 3, w: 3, h: 2, static: true }
    ]
  }

  return (
    <Space direction={'vertical'} size={'small'} style={{ width: '100%' }}>
      <Helmet title={'Operator Dashboard'} />
      <CollectPageHeader title={'Operator Dashboard'} />
      <FilterBar
        showRefresh={true}
        stores={[contactSummaryOperatorStore, fiveDayCollectionsByOperatorStore, overallSummaryOperatorStore]}
      />
      <RGL breakpoints={breakpoints} cols={cols} containerPadding={[0, 0]} layouts={layouts} rowHeight={100}>
        <div key={'operator-cctr'}>
          <Widget>
            <OverallSummary
              label={AuthStore.payload?.nx_operator?.op_name}
              store={overallSummaryOperatorStore}
              type={'operator'}
            />
          </Widget>
        </div>
        <div key={'operator-contacts'}>
          <Widget title={`Contacts for ${AuthStore.payload?.nx_operator?.op_name}`}>
            <ContactSummary store={contactSummaryOperatorStore} type={'operator'} />
          </Widget>
        </div>
        <div key={'operator-collections'}>
          <Widget title={`Five Day Collections for ${AuthStore.payload?.nx_operator?.op_name}`}>
            <CollectionsHistory store={fiveDayCollectionsByOperatorStore} type={'operator'} />
          </Widget>
        </div>
      </RGL>
    </Space>
  )
})

export default OperatorDashboard

import { Col, Empty, Row } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Spinner from '../../../../../shared/components/Spinner'
import { ObjectLiteral } from '../../../../../shared/interfaces/object'
import { getNumberFormat } from '../../../../../shared/utils/intl'
import { ChartStoreInstance } from '../../../stores/chart'

interface TodayContactTypeBreakdownProps {
  store: ChartStoreInstance
}

const TodayContactTypeBreakdown: React.FC<TodayContactTypeBreakdownProps> = observer(
  (props: TodayContactTypeBreakdownProps) => {
    const intl = useIntl()
    const { store } = props

    useEffect(() => {
      if (!props.store) {
        store.api_getData()
      }
    }, [])

    const dataSource = (): ObjectLiteral[] => {
      const arr: ObjectLiteral[] = []

      if (store.payload) {
        arr.push(...JSON.parse(store.payload))
      }

      return arr
    }

    return store.meta.request.loading ? (
      <Spinner active={store.meta.request.loading} />
    ) : dataSource()?.length > 0 ? (
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}>
          <ResponsiveContainer>
            <ComposedChart data={dataSource()}>
              <CartesianGrid strokeDasharray={'3 3'} />
              <XAxis
                dataKey={'co_type'}
                scale={'band'}
                tickFormatter={(value) => {
                  return (function (val) {
                    switch (val) {
                      case 0:
                        return 'Letter'
                      case 1:
                        return 'Phone'
                      case 3:
                        return 'Promise'
                      case 5:
                        return 'Review'
                      case 6:
                        return 'Other'
                      case 19:
                        return 'Email'
                      default:
                        return ''
                    }
                  })(value)
                }}
              />
              <YAxis
                yAxisId={'left'}
                dataKey={'count'}
                orientation={'left'}
                tickFormatter={(value) => intl.formatNumber(value ?? 0, getNumberFormat())}
              />
              <YAxis
                yAxisId={'right'}
                dataKey={'avg_elapsed_time'}
                orientation={'right'}
                tickFormatter={(value) => intl.formatNumber(value ?? 0, getNumberFormat())}
              />
              <Tooltip />
              <Legend />
              <Bar yAxisId={'left'} dataKey={'count'} name={'# of Contacts'} fill={'#2BA3BB'} />
              <Line
                yAxisId={'right'}
                dataKey={'avg_elapsed_time'}
                name={'Avg Time Worked (seconds)'}
                stroke={'#F89939'}
                type={'monotone'}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    ) : (
      <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
        <Col>
          <Empty />
        </Col>
      </Row>
    )
  }
)

export default TodayContactTypeBreakdown

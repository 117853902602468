import { ComponentType, createElement, lazy } from 'react'
import { matchPath } from 'react-router-dom'
import { ObjectLiteral } from '../../interfaces/object'

// Source: https://dev.to/marinovicmarko/preloading-blog-post-content-f8a
type PreloadComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<void>
}

// Source: https://dev.to/marinovicmarko/preloading-blog-post-content-f8a
export const lazyPreload = <T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) => {
  let LoadedComponent: T | undefined
  let factoryPromise: Promise<void> | undefined

  const LazyComponent = lazy(factory)

  const loadComponent = () =>
    factory().then((module) => {
      LoadedComponent = module.default
    })

  const Component = ((props) => createElement(LoadedComponent || LazyComponent, props)) as PreloadComponent<T>

  Component.preload = () => factoryPromise || loadComponent()

  return Component
}

// Source: https://medium.com/maxime-heckel/react-lazy-a-take-on-preloading-views-cc90be869f14
const findComponentForRoute = (path: string, routes: ObjectLiteral[]) => {
  const matchingRoute = routes.find((route) => {
    return matchPath(path, {
      path: route.path
    })
  })

  return matchingRoute ? matchingRoute.component : undefined
}

// Source: https://medium.com/maxime-heckel/react-lazy-a-take-on-preloading-views-cc90be869f14
export const preloadRouteComponent = (path: string, routes: ObjectLiteral[]) => {
  const component = findComponentForRoute(path, routes)

  if (component?.preload) {
    component.preload()
  }
}

import humanizeDuration from 'humanize-duration'

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const upperFirst = (value?: string) => {
  if (!value) return undefined
  return `${value.substring(0, 1).toUpperCase()}${value.substring(1)}`
}

export const prettyPrintSeconds = (valueInSeconds: number) => {
  return humanizeDuration(valueInSeconds, { largest: 3, round: true })
}

import { DownOutlined } from '@ant-design/icons'
import { Button, Popover, Radio, RadioChangeEvent, Space, Typography } from 'antd'
import React from 'react'

interface RefreshButtonProps {
  setRefreshAfter: (number: number) => void
  refreshAfter: number
}

const radioStyle: React.CSSProperties = {
  display: 'block',
  height: '35px',
  lineHeight: '40px'
}

const radioOptions = [
  { label: 'Never', value: -1 },
  { label: '5 Minutes', value: 300 },
  { label: '15 Minutes', value: 900 },
  { label: '30 Minutes', value: 1800 },
  { label: '1 Hour', value: 3600 }
]

const RefreshButton = (props: RefreshButtonProps) => {
  const { Text } = Typography

  return (
    <Popover
      content={
        <div style={{ width: '100px' }}>
          <Space direction={'vertical'}>
            <Radio.Group
              onChange={(e: RadioChangeEvent) => {
                props.setRefreshAfter(e.target.value)
              }}
              defaultValue={props.refreshAfter}
              value={props.refreshAfter}
              options={radioOptions}
            >
              {radioOptions.map((opt) => {
                return (
                  <Radio.Button key={opt.value} style={radioStyle} value={opt.value}>
                    {opt.label}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Space>
        </div>
      }
      placement={'bottomRight'}
      trigger={'click'}
    >
      <Button data-cy={`filter-bar-refresh`} type={props.refreshAfter !== -1 ? 'primary' : 'default'}>
        <span>
          <Text style={{ color: props.refreshAfter !== -1 ? '#F2F2F2' : '#8C8C8C' }}>Refresh: </Text>
          <Text strong style={{ color: props.refreshAfter !== -1 ? '#F2F2F2' : '#000' }}>
            {radioOptions.filter((o) => o.value === props.refreshAfter)[0].label}
          </Text>
        </span>
        <DownOutlined />
      </Button>
    </Popover>
  )
}

export default RefreshButton

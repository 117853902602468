import { castToSnapshot, flow, Instance, types } from 'mobx-state-tree'
import { PicklistOptionModel, PicklistOptionModelInstance } from '.'
import { ObjectLiteral } from '../../../shared/interfaces/object'
import { actionFailure, actionStart, actionSuccess, FlowState, FlowStateEnum } from '../../../shared/stores'
import { createMetaModel, CreateMetaModelProps, MetaModel } from '../../../shared/stores/meta'
import { chunkArray } from '../../../shared/utils/array'
import { sleep } from '../../../shared/utils/generic'
import { CollectPicklistApiService } from '../services/api/collect'

const CollectPicklistStore = types
  .model('CollectPicklistStore', {
    state: FlowStateEnum,
    options: types.map(PicklistOptionModel),
    selectedOptions: types.map(PicklistOptionModel),
    error: types.maybe(types.string),
    meta: MetaModel
  })
  .actions((self) => {
    const api_getPicklist = flow(function* () {
      actionStart(self)

      try {
        const args = self.meta.request.args

        const pl_uuid = args?.get('pl_uuid')?.value
        if (!pl_uuid) throw new Error('Arg Error: `pl_uuid` is required')

        const response = yield CollectPicklistApiService.getPicklist(pl_uuid, self.meta.request.getRequestParams)
        const { result } = response.data

        self.options.clear()
        for (const chunk of chunkArray(result)) {
          chunk.map((r: ObjectLiteral) => saveObject(r))
          yield sleep(100)
        }
        actionSuccess(self)

        return response
      } catch (error) {
        actionFailure(self, error)
        throw error
      }
    })

    const saveObject = (data: ObjectLiteral) => {
      const option = PicklistOptionModel.create({
        key: data.key,
        value: data.value
      })
      self.options.put(option)
    }

    const clearSelectedOptions = () => {
      self.selectedOptions.clear()
    }

    const toggleSelectedOption = (option: PicklistOptionModelInstance) => {
      const existing = self.selectedOptions.get(option.key)
      !existing ? self.selectedOptions.set(option.key, option) : self.selectedOptions.delete(option.key)
    }

    return {
      api_getPicklist,
      saveObject,
      clearSelectedOptions,
      toggleSelectedOption
    }
  })

export interface CollectPicklistStoreInstance extends Instance<typeof CollectPicklistStore> {}

interface CreateCollectPicklistStoreProps {
  meta: CreateMetaModelProps
}

export const createCollectPicklistStore = (props?: CreateCollectPicklistStoreProps) => {
  return CollectPicklistStore.create({
    state: FlowState.IDLE,
    meta: castToSnapshot(createMetaModel(props?.meta))
  })
}

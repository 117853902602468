import { castToSnapshot, flow, Instance, types } from 'mobx-state-tree'
import { ObjectLiteral } from '../../../shared/interfaces/object'
import { actionFailure, actionStart, actionSuccess, FlowState } from '../../../shared/stores'
import { createMetaModel, MetaModel } from '../../../shared/stores/meta'
import { NxUserApiService } from '../services/api/nx-user'

const NxUserModel = types.model('NxUserModel', {
  us_rowid: types.identifier,
  us_name: types.maybeNull(types.string),
  us_address: types.maybeNull(types.string),
  us_user_1: types.maybeNull(types.string),
  us_state: types.maybeNull(types.string),
  us_zip: types.maybeNull(types.string),
  us_country: types.maybeNull(types.string)
})

export interface NxUserModelInstance extends Instance<typeof NxUserModel> {}

export const NxUserStore = types
  .model('NxUserStore', {
    state: FlowState.IDLE,
    payload: types.maybe(NxUserModel),
    error: types.maybe(types.string),
    meta: MetaModel
  })
  .actions((self) => {
    const api_getCompanyDetails = flow(function* () {
      actionStart(self)

      try {
        const response = yield NxUserApiService.getCompanyDetails()
        const { result } = response.data

        actionSuccess(self)
        saveObject(result)

        return response
      } catch (error) {
        actionFailure(self, error)
        throw error
      }
    })

    const saveObject = (data: ObjectLiteral) => {
      const { us_rowid, ...rest } = data
      const model = NxUserModel.create({
        us_rowid: us_rowid,
        ...rest
      })
      self.payload = model
    }

    return {
      api_getCompanyDetails,
      saveObject
    }
  })
  .create({
    state: FlowState.IDLE,
    meta: castToSnapshot(
      createMetaModel({
        request: {
          loading: false
        },
        response: {
          pagination: false
        }
      })
    )
  })

export interface NxUserStoreInstance extends Instance<typeof NxUserStore> {}

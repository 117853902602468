import { ObjectLiteral } from '../interfaces/object'

export function chunkArray(arr: ObjectLiteral[], chunkSize: number = 500) {
  let results = []

  while (arr.length) {
    results.push(arr.splice(0, chunkSize))
  }

  return results
}

import { Col, PageHeader, Row } from 'antd'
import { PageHeaderProps } from 'antd/lib/page-header'
import React from 'react'
import DownloadButton, { DownloadButtonProps } from './DownloadButton'

interface CollectPageHeaderProps extends PageHeaderProps {
  downloadButtonProps?: DownloadButtonProps
}

const CollectPageHeader: React.FC<CollectPageHeaderProps> = ({
  style,
  downloadButtonProps,
  ...rest
}: CollectPageHeaderProps) => {
  return (
    <div data-cy={'page-header'}>
      <Row>
        <Col flex={1}>
          <PageHeader
            style={{
              padding: '0 0',
              ...style
            }}
            {...rest}
          />
        </Col>
        <Col flex={'0 1'}>{downloadButtonProps && <DownloadButton csvItems={downloadButtonProps.csvItems} />}</Col>
      </Row>
    </div>
  )
}

export default CollectPageHeader

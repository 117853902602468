import { Space } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Helmet } from 'react-helmet-async'
import FilterBar from '../../../../shared/components/FilterBar'
import CollectPageHeader from '../../../../shared/components/PageHeader'
import { AuthStore } from '../../../auth/stores'
import { createChartStore } from '../../stores/chart'
import CollectionsHistory from '../charts/CollectionsHistory'
import ListingSummary from '../charts/ListingSummary'
import OverallSummary from '../charts/OverallSummary'
import Widget from '../shared/Widget'

const RGL = WidthProvider(Responsive)

const ClientDashboard: React.FC = observer(() => {
  const [fiveDayCollectionsByClientStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/client/five-day-collections` } }
    })
  })

  const [listingSummaryStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: '/api/v1/dashboard/dashboard/client/listed-summary' } }
    })
  })

  const [overallSummaryClientStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/client/overall-summary` } }
    })
  })

  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }
  const cols = { lg: 6, md: 6, sm: 6, xs: 3, xxs: 3 }
  const layouts = {
    lg: [
      { i: 'client-cctr', x: 0, y: 0, w: 6, h: 1, static: true },
      { i: 'client-operator-collections', x: 0, y: 1, w: 3, h: 2, static: true },
      { i: 'client-listings', x: 3, y: 1, w: 3, h: 2, static: true }
    ],
    xs: [
      { i: 'client-cctr', x: 0, y: 0, w: 6, h: 1, static: true },
      { i: 'client-operator-collections', x: 0, y: 1, w: 6, h: 2, static: true },
      { i: 'client-listings', x: 0, y: 3, w: 6, h: 2, static: true }
    ]
  }

  return (
    <Space direction={'vertical'} size={'small'} style={{ width: '100%' }}>
      <Helmet title={'Client Dashboard'} />
      <CollectPageHeader title={'Client Dashboard'} />
      <FilterBar
        showRefresh={true}
        stores={[fiveDayCollectionsByClientStore, listingSummaryStore, overallSummaryClientStore]}
      />
      <RGL breakpoints={breakpoints} cols={cols} layouts={layouts} rowHeight={100}>
        <div key={'client-cctr'}>
          <Widget>
            <OverallSummary
              label={AuthStore.payload?.nx_operator?.op_name ?? ''}
              store={overallSummaryClientStore}
              type={'client'}
            />
          </Widget>
        </div>
        <div key={'client-operator-collections'}>
          <Widget title={`Five Day Collections for ${AuthStore.payload?.nx_operator?.op_name}`}>
            <CollectionsHistory store={fiveDayCollectionsByClientStore} type={'client'} />
          </Widget>
        </div>
        <div key={'client-listings'}>
          <Widget title={'Last 10 Days Listing Summary'}>
            <ListingSummary store={listingSummaryStore} />
          </Widget>
        </div>
      </RGL>
    </Space>
  )
})

export default ClientDashboard

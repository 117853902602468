import jsonwebtoken from 'jsonwebtoken'
import { observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTimer } from 'use-timer'
import { ObjectLiteral } from '../../../shared/interfaces/object'
import { AuthStore } from '../../auth/stores'

const RefreshTokenTimer: React.FC = observer(() => {
  const [tokenTimeRemaining, setTokenTimeRemaining] = useState<number>(
    moment((jsonwebtoken.decode(AuthStore.meta.accessToken!) as ObjectLiteral).exp * 1000).diff(moment(), 's')
  )

  const { reset, start, time } = useTimer({
    onTimeOver: async () => {
      reset()
      start()
    },
    endTime: 0,
    initialTime: Math.floor(tokenTimeRemaining * 0.8),
    timerType: 'DECREMENTAL'
  })

  useEffect(() => {
    start()
  }, [])

  useEffect(() => {
    if (time === 0) {
      ;(async () => {
        await AuthStore.api_refreshToken().then(() => {
          setTokenTimeRemaining(
            moment((jsonwebtoken.decode(AuthStore.meta.accessToken!) as ObjectLiteral).exp * 1000).diff(moment(), 's')
          )
        })
      })()
    }
  }, [time])

  return <React.Fragment></React.Fragment>
})

export default RefreshTokenTimer

import { castToSnapshot, flow, Instance, types } from 'mobx-state-tree'
import { ObjectLiteral } from '../../../shared/interfaces/object'
import { actionFailure, actionStart, actionSuccess, FlowState, FlowStateEnum } from '../../../shared/stores'
import { createMetaModel, CreateMetaModelProps, MetaModel } from '../../../shared/stores/meta'
import { chunkArray } from '../../../shared/utils/array'
import { sleep } from '../../../shared/utils/generic'
import { PicklistApiService } from '../services/api'

export const PicklistOptionModel = types.model('PicklistOptionModel', {
  key: types.identifier,
  value: types.string,
  extra: types.maybe(types.string)
})

export interface PicklistOptionModelInstance extends Instance<typeof PicklistOptionModel> {}

const PicklistStore = types
  .model('PicklistStore', {
    state: FlowStateEnum,
    options: types.map(PicklistOptionModel),
    selectedOptions: types.map(PicklistOptionModel),
    error: types.maybe(types.string),
    meta: MetaModel
  })
  .actions((self) => {
    const api_getPicklist = flow(function* () {
      actionStart(self)

      try {
        const args = self.meta.request.args

        const scope = args?.get('scope')?.value
        const key = args?.get('key')?.value
        const value = args?.get('value')?.value
        const extra = args?.get('extra')?.value

        if (!scope) throw new Error('Arg Error: `scope` is required')
        if (!key) throw new Error('Arg Error: `key` is required')
        if (!value) throw new Error('Arg Error: `value` is required')

        const response = yield PicklistApiService.getPicklist(
          scope,
          key,
          value,
          extra,
          self.meta.request.getRequestParams
        )
        const { result } = response.data

        self.options.clear()
        for (const chunk of chunkArray(result)) {
          chunk.map((r: ObjectLiteral) => saveObject(r))
          yield sleep(100)
        }
        actionSuccess(self)

        return response
      } catch (error) {
        actionFailure(self, error)
        throw error
      }
    })

    const saveObject = (data: ObjectLiteral) => {
      const extra = self.meta.request.args?.get('extra')?.value ?? undefined
      let optionData = {
        key: data.key,
        value: data.value
      }
      if (extra) Object.assign(optionData, { extra: String(data[extra]) })

      const option = PicklistOptionModel.create(optionData)
      self.options.put(option)
    }

    const clearSelectedOptions = () => {
      self.selectedOptions.clear()
    }

    const toggleSelectedOption = (option: PicklistOptionModelInstance) => {
      const existing = self.selectedOptions.get(option.key)
      !existing ? self.selectedOptions.set(option.key, option) : self.selectedOptions.delete(option.key)
    }

    return {
      api_getPicklist,
      saveObject,
      clearSelectedOptions,
      toggleSelectedOption
    }
  })

export interface PicklistStoreInstance extends Instance<typeof PicklistStore> {}

interface CreatePicklistStoreProps {
  meta: CreateMetaModelProps
}

export const createPicklistStore = (props?: CreatePicklistStoreProps) => {
  return PicklistStore.create({
    state: FlowState.IDLE,
    meta: castToSnapshot(createMetaModel(props?.meta))
  })
}

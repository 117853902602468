import { createBrowserHistory } from 'history'
import { Instance, types } from 'mobx-state-tree'
import { RouterModel, syncHistoryWithStore } from 'mst-react-router'

export const RouterStore = types
  .model('RouterStore', {
    router: RouterModel
  })
  .create({
    router: RouterModel.create()
  })

export interface RouterStoreInstance extends Instance<typeof RouterStore> {}
export const history = syncHistoryWithStore(createBrowserHistory(), RouterStore.router)

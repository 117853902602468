import { Col, Empty, Row } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import LoadingSpinner from '../../../../shared/components/Spinner'
import { ObjectLiteral } from '../../../../shared/interfaces/object'
import { getCurrencyFormat } from '../../../../shared/utils/intl'
import { ChartStoreInstance } from '../../stores/chart'

export interface CollectionsByOperatorProps {
  store: ChartStoreInstance
  type: 'bottom' | 'top'
}

const CollectionsByOperator: React.FC<CollectionsByOperatorProps> = observer((props: CollectionsByOperatorProps) => {
  const intl = useIntl()
  const { store } = props

  useEffect(() => {
    store.api_getData()
  }, [])

  const dataSource = (): ObjectLiteral[] | undefined =>
    store.payload
      ? JSON.parse(store.payload).map((data: ObjectLiteral) => {
          return {
            tr_who: data.tr_who,
            sum_collected: data.sum_collected,
            sum_commission: data.sum_commission
          }
        })
      : undefined

  return store.meta.request.loading ? (
    <LoadingSpinner />
  ) : (dataSource() as any)?.length > 0 ? (
    // https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
        <ResponsiveContainer>
          <BarChart data={dataSource()}>
            <CartesianGrid strokeDasharray={'3 3'} />
            <XAxis dataKey={'tr_who'} />
            <YAxis
              tickFormatter={(value) => intl.formatNumber(value ?? 0, getCurrencyFormat({ notation: 'compact' }))}
              width={40}
            />
            <Tooltip
              formatter={(value: any, name: any, item: any) => {
                switch (item.dataKey) {
                  case 'sum_collected':
                  case 'sum_commission': {
                    return intl.formatNumber(value, getCurrencyFormat())
                  }
                  default:
                    return value
                }
              }}
            />
            <Legend />
            <Bar dataKey={'sum_collected'} name={'Collected'} fill={'#1A3373'} />
            <Bar dataKey={'sum_commission'} name={'Commission'} fill={'#2BA3BB'} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  ) : (
    <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
      <Col>
        <Empty />
      </Col>
    </Row>
  )
})

export default CollectionsByOperator

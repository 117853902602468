import { Instance, types } from 'mobx-state-tree'

export const NxOperatorModel = types.model('NxOperatorModel', {
  op_rowid: types.identifier,
  op_id: types.maybe(types.string),
  op_level: types.maybe(types.number),
  op_function: types.maybe(types.number),
  op_name: types.maybe(types.string)
})

export interface NxOperatormodelInstance extends Instance<typeof NxOperatorModel> {}

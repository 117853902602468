import { Col, Empty, Row } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { ChartProps } from '.'
import Spinner from '../../../../shared/components/Spinner'
import { ObjectLiteral } from '../../../../shared/interfaces/object'
import { ChartStoreInstance } from '../../stores/chart'

export interface ContactSummaryProps extends ChartProps {
  store: ChartStoreInstance
  type: 'manager' | 'operator'
}

const ContactSummary: React.FC<ContactSummaryProps> = observer((props: ContactSummaryProps) => {
  const { store } = props

  useEffect(() => {
    store.api_getData()
  }, [])

  const dataSource = (): ObjectLiteral[] | undefined => {
    let retArr: ObjectLiteral[] = []
    const data = store.payload ? JSON.parse(store.payload) : undefined

    if (data) {
      retArr.push(
        {
          name: 'Incomplete',
          value: data['incomplete']
        },
        {
          name: 'Complete',
          value: data['completed']
        }
      )
    }

    return retArr
  }

  const numberOfContactsForToday: number = !(dataSource() == null)
    ? dataSource()
        ?.map((obj) => obj.value)
        .reduce((a, b) => a + b, 0)
    : 0

  const colors = ['#F89939', '#2BA3BB']

  return store.meta.request.loading ? (
    <Spinner />
  ) : numberOfContactsForToday > 0 ? (
    // https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie data={dataSource()} dataKey={'value'}>
              {(dataSource() as any).map((entry: ObjectLiteral, index: number) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  ) : (
    <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
      <Col>
        <Empty />
      </Col>
    </Row>
  )
})

export default ContactSummary

import styled from 'styled-components'

const LogoWrapper = styled.div`
  width: 120px;
  height: 31px;
  background: '#FFF';

  margin-top: 16px;
  line-height: 0;

  img {
    width: 100%;
  }
`

export default LogoWrapper

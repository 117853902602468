import { PaginatedRequestParams } from '../../../../shared/interfaces/request'
import { createRequestUrl } from '../../../../shared/utils/request'
import { CollectApiClient } from '../../../collect/services/api/client'

const getChart = (url: string, params?: PaginatedRequestParams) => {
  return CollectApiClient({
    method: 'GET',
    url: createRequestUrl(url, params),
  })
}

export const DashboardApiService = {
  getChart,
}

import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import LoginForm from './Form'

const Login: React.FC = observer(() => {
  return (
    <React.Fragment>
      <Helmet title={'Login'} />
      <LoginForm />
    </React.Fragment>
  )
})

export default Login

import { createRequestUrl } from '../../../../shared/utils/request'
import { CollectApiClient } from '../../../collect/services/api/client'

const login = (op_id: string, op_password: string) =>
  CollectApiClient({
    method: 'POST',
    url: createRequestUrl('/api/v1/auth/login'),
    data: {
      op_id: op_id,
      op_password: op_password
    }
  })

const getProfile = () =>
  CollectApiClient({
    method: 'GET',
    url: createRequestUrl('/api/v1/auth/profile')
  })

const refreshToken = (refreshToken?: string) =>
  CollectApiClient({
    method: 'POST',
    url: createRequestUrl('/api/v1/auth/refresh'),
    headers: {
      Refresh: refreshToken
    }
  })

const logout = () =>
  CollectApiClient({
    method: 'POST',
    url: createRequestUrl('/api/v1/auth/logout')
  })

export const AuthApiService = {
  login,
  getProfile,
  refreshToken,
  logout
}

import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import Cookies from 'universal-cookie'
import Spinner from '../../../shared/components/Spinner'
import { AuthStore } from '../stores'

const Logout: React.FC = observer(() => {
  useEffect(() => {
    if (AuthStore.meta.authenticated) AuthStore.api_logout()

    const cookies = new Cookies()
    if (cookies.get('x-collect-database')) cookies.remove('x-collect-database', { path: '/' })
  }, [])

  return <Spinner />
})

export default Logout

import { getParentOfType, getSnapshot, Instance, types } from 'mobx-state-tree'
import { PaginatedRequestParams, RequestParams } from '../interfaces/request'

const RequestArgModel = types.model('RequestargsModel', {
  key: types.identifier,
  value: types.maybe(types.string)
})

export interface RequestArgModelInstance extends Instance<typeof RequestArgModel> {}

export const RequestFilterModel = types.model('RequestFilterModel', {
  field: types.string,
  operation: types.string,
  value: types.maybe(types.string)
})

export interface RequestFilterModelInstance extends Instance<typeof RequestFilterModel> {}

const RequestSortModel = types.model('RequestSortModel', {
  field: types.string,
  direction: types.union(types.literal('ascend'), types.literal('descend'))
})

export interface RequestSortModelInstance extends Instance<typeof RequestSortModel> {}

const RequestPaginationModel = types
  .model('RequestPaginationModel', {
    limit: types.maybe(types.number),
    page: types.maybe(types.number)
  })
  .actions((self) => {
    const setLimit = (limit: number) => {
      self.limit = limit
    }

    const setPage = (page: number) => {
      self.page = page
    }

    return {
      setLimit,
      setPage
    }
  })
  .views((self) => ({
    get getPaginatedRequestParams(): PaginatedRequestParams {
      const params = getParentOfType(self, RequestModel).getRequestParams

      return {
        limit: self.limit,
        page: self.page,
        ...params
      }
    }
  }))

export interface RequestPaginationModelInstance extends Instance<typeof RequestPaginationModel> {}

export const RequestModel = types
  .model('RequestModel', {
    loading: types.boolean,
    url: types.maybe(types.string),

    defaultFilters: types.map(RequestFilterModel),
    defaultSort: types.map(RequestSortModel),

    args: types.map(RequestArgModel),
    filters: types.map(RequestFilterModel),
    search: types.maybe(types.string),
    sorts: types.map(RequestSortModel),

    pagination: types.maybe(RequestPaginationModel)
  })
  .actions((self) => {
    const addFilter = (filter: RequestFilterModelInstance) => {
      self.filters.set(`${filter.field}__${filter.operation}`, filter)
    }

    const addSort = (sort: RequestSortModelInstance) => {
      self.sorts.set(sort.field, sort)
    }

    const removeFilter = (filter: RequestFilterModelInstance) => {
      self.filters.delete(`${filter.field}__${filter.operation}`)
    }

    const removeSort = (sort: RequestSortModelInstance) => {
      self.sorts.delete(sort.field)
    }

    const resetFilter = () => {
      self.filters.replace(getSnapshot(self.defaultFilters))
    }

    const resetSort = () => {
      self.sorts.replace(getSnapshot(self.defaultSort))
    }

    const setLoading = (loading: boolean) => {
      self.loading = loading
    }

    const setSearch = (searchTerm?: string) => {
      self.search = searchTerm
    }

    const setSort = (sort: Map<string, RequestSortModelInstance>) => {
      self.sorts.replace(sort)
    }

    return {
      addFilter,
      addSort,
      removeFilter,
      removeSort,
      resetFilter,
      resetSort,
      setLoading,
      setSearch,
      setSort
    }
  })
  .views((self) => ({
    get getRequestParams(): RequestParams {
      const filterString = Array.from(self.filters.values())
        .filter((f) => f.value)
        .map((f) => {
          return f.operation === 'in'
            ? `${f.field}__${f.operation}=(${f.value})`
            : `${f.field}__${f.operation}=${f.value}`
        })
        .join(',')

      const sortString = Array.from(self.sorts.values())
        .map((s) => `${s.direction === 'descend' ? '-' : ''}${s.field}`)
        .join(',')

      return {
        ...(filterString.length > 0 && { filter: filterString }),
        ...(sortString.length > 0 && { sort: sortString }),
        ...(self.search && self.search.length > 0 && { search: self.search })
      }
    }
  }))

export interface RequestModelInstance extends Instance<typeof RequestModel> {}

const ResponsePaginationModel = types
  .model('ResponsePaginationModel', {
    count: types.maybe(types.number),
    limit: types.maybe(types.number),
    page: types.maybe(types.number),
    pageCount: types.maybe(types.number)
  })
  .actions((self) => {
    const setCount = (count: number) => {
      self.count = count
    }

    const setLimit = (limit: number) => {
      self.limit = limit
    }

    const setPage = (page: number) => {
      self.page = page
    }

    const setPageCount = (pageCount: number) => {
      self.pageCount = pageCount
    }

    return {
      setCount,
      setLimit,
      setPage,
      setPageCount
    }
  })

export interface ResponsePaginationModelInstance extends Instance<typeof ResponsePaginationModel> {}

export const ResponseModel = types.model('ResponseModel', {
  pagination: types.maybe(ResponsePaginationModel)
})

export interface ResponseModelInstance extends Instance<typeof ResponseModel> {}

export const MetaModel = types.model('MetaModel', {
  request: RequestModel,
  response: ResponseModel
})

export interface MetaModelInstance extends Instance<typeof MetaModel> {}

export interface CreateMetaModelProps {
  request?: {
    loading: boolean
    url?: string
    args?: { [key: string]: RequestArgModelInstance }
    filters?: { [key: string]: RequestFilterModelInstance }
    search?: string
    sorts?: { [key: string]: RequestSortModelInstance }
    pagination?: {
      limit: number
      page: number
    }
  }
  response?: {
    pagination?: boolean
  }
}

export const createMetaModel = (props?: CreateMetaModelProps): MetaModelInstance => {
  return MetaModel.create({
    request: getSnapshot(
      RequestModel.create({
        loading: props?.request?.loading ? props.request.loading : false,
        url: props?.request?.url,
        ...(props?.request?.args && { args: props.request.args }),
        ...(props?.request?.filters && { defaultFilters: props.request.filters, filters: props.request.filters }),
        ...(props?.request?.sorts && { defaultSorts: props.request.sorts, sorts: props.request.sorts }),
        search: props?.request?.search,
        ...(props?.request?.pagination && {
          pagination: RequestPaginationModel.create({
            limit: props.request.pagination.limit,
            page: props.request.pagination.page
          })
        })
      })
    ),
    response: ResponseModel.create({
      ...(props?.response?.pagination && { pagination: ResponsePaginationModel.create() })
    })
  })
}

import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Popover, Row, Space, Typography } from 'antd'
import { observer } from 'mobx-react'
import moment from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import React from 'react'
import { FilterBarStoreInstance } from '../../stores'

interface DateRangeFilterProps {
  filterBarStore: FilterBarStoreInstance
  label: string
  name: string | [string, string]
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = observer((props: DateRangeFilterProps) => {
  const { RangePicker } = DatePicker
  const { Text } = Typography

  const filterValuesExist =
    props.filterBarStore.filters.get(`${Array.isArray(props.name) ? props.name[0] : props.name}__gte`)?.value &&
    props.filterBarStore.filters.get(`${Array.isArray(props.name) ? props.name[1] : props.name}__lte`)?.value
  const defaultValues = (): RangeValue<moment.Moment> => {
    return filterValuesExist
      ? [
          moment(
            props.filterBarStore.filters.get(`${Array.isArray(props.name) ? props.name[0] : props.name}__gte`)?.value
          ),
          moment(
            props.filterBarStore.filters.get(`${Array.isArray(props.name) ? props.name[1] : props.name}__lte`)?.value
          )
        ]
      : null
  }

  return (
    <Popover
      content={
        <Space direction={'vertical'} size={'small'}>
          <Row>
            <Col flex={1}>
              <Text strong>{props.label}</Text>
            </Col>
            <Col flex={0} style={{ textAlign: 'right' }}>
              {filterValuesExist && (
                <Button
                  onClick={() => {
                    props.filterBarStore.addFilter({
                      field: Array.isArray(props.name) ? props.name[0] : props.name,
                      operation: 'gte',
                      value: undefined
                    })
                    props.filterBarStore.addFilter({
                      field: Array.isArray(props.name) ? props.name[1] : props.name,
                      operation: 'lte',
                      value: undefined
                    })
                  }}
                  size={'small'}
                  type={'link'}
                >
                  <CloseOutlined /> Clear
                </Button>
              )}
            </Col>
          </Row>
          <RangePicker
            onChange={(values: RangeValue<moment.Moment>, formatString: [string, string]) => {
              props.filterBarStore.addFilter({
                field: Array.isArray(props.name) ? props.name[0] : props.name,
                operation: 'gte',
                value: values?.[0]?.format('YYYY-MM-DD') ?? undefined
              })
              props.filterBarStore.addFilter({
                field: Array.isArray(props.name) ? props.name[1] : props.name,
                operation: 'lte',
                value: values?.[1]?.format('YYYY-MM-DD') ?? undefined
              })
            }}
            name={Array.isArray(props.name) ? props.name.join('-') : props.name}
            defaultValue={defaultValues()}
            value={defaultValues()}
          />
        </Space>
      }
      placement={'bottomLeft'}
      trigger={'click'}
    >
      <Button
        data-cy={`filter-bar-filters-date-range-${Array.isArray(props.name) ? props.name.join('-') : props.name}`}
        type={filterValuesExist ? 'primary' : 'default'}
      >
        <Space size={'small'}>
          <span>
            <Text style={{ color: filterValuesExist ? '#F2F2F2' : '#8C8C8C' }}>{props.label}: </Text>
            <Text strong style={{ color: filterValuesExist ? '#F2F2F2' : '#000' }}>
              {Array.isArray(props.name)
                ? props.filterBarStore.filters?.get(`${props.name[0]}__gte`)?.value &&
                  props.filterBarStore.filters?.get(`${props.name[1]}__lte`)?.value
                  ? `${moment(props.filterBarStore.filters.get(`${props.name[0]}__gte`)?.value)
                      .format('MM/DD/YYYY')
                      .toString()} - ${moment(props.filterBarStore.filters.get(`${props.name[1]}__lte`)?.value)
                      .format('MM/DD/YYYY')
                      .toString()}`
                  : 'All'
                : props.filterBarStore.filters?.get(`${props.name}__gte`)?.value &&
                  props.filterBarStore.filters?.get(`${props.name}__lte`)?.value
                ? `${moment(props.filterBarStore.filters.get(`${props.name}__gte`)?.value)
                    .format('MM/DD/YYYY')
                    .toString()} - ${moment(props.filterBarStore.filters.get(`${props.name}__lte`)?.value)
                    .format('MM/DD/YYYY')
                    .toString()}`
                : 'All'}
            </Text>
          </span>
          <DownOutlined />
        </Space>
      </Button>
    </Popover>
  )
})

export default DateRangeFilter

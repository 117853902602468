import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { joiResolver } from '@hookform/resolvers/joi'
import { Button, Card, Col, Divider, Form, Input, Row, Select, Space, Table, Typography } from 'antd'
import { AxiosError } from 'axios'
import Joi from 'joi'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import Cookies from 'universal-cookie'
import { ObjectLiteral } from '../../../../shared/interfaces/object'
import { AuthStore } from '../../stores'

const LogoWrapper = styled.div`
  img {
    display: block;
    margin: 0 auto 8px;
    width: 60%;
  }
`

const LoginSchema = Joi.object({
  username: Joi.string().max(16).label('Username').required(),
  password: Joi.string().max(48).label('Password').required()
})

const LoginForm: React.FC = observer(() => {
  const { addToast } = useToasts()
  const { handleSubmit, trigger, control, errors } = useForm({
    resolver: joiResolver(LoginSchema),
    mode: 'all'
  })

  const cookies = new Cookies()

  const onSubmit = async (values: ObjectLiteral) => {
    const valid = await trigger(Object.keys(values))
    if (valid) {
      AuthStore.api_login(values.username, values.password).catch((error: AxiosError | Error) => {
        const axiosErrorData = (error as AxiosError).response?.data
        addToast(
          <div>
            Error
            <br />
            {axiosErrorData ? axiosErrorData.message : error.message}
          </div>,
          { appearance: 'error', autoDismiss: true }
        )
      })
    }
  }

  const { Text } = Typography

  const demoConfig = (window as ObjectLiteral).runtimeConfig?.demo
  const tableData = demoConfig
    ? {
        columns: [
          {
            title: 'User',
            dataIndex: 'label',
            key: 'label'
          },
          {
            title: 'Username',
            dataIndex: 'username',
            key: 'username'
          },
          {
            title: 'Password',
            dataIndex: 'password',
            key: 'password'
          }
        ],
        data: demoConfig?.accounts
      }
    : undefined

  const databaseConfig = (window as ObjectLiteral).runtimeConfig?.databases
  const [dbPickerValue, setDbPickerValue] = useState<string | undefined>(
    databaseConfig?.length > 0 ? databaseConfig[0].value : undefined
  )

  useEffect(() => {
    if (dbPickerValue !== undefined) {
      cookies.set('x-collect-database', dbPickerValue, { path: '/' })
    } else {
      cookies.remove('x-collect-database', { path: '/' })
    }
  }, [dbPickerValue])

  return (
    <Row align={'middle'} justify={'center'} style={{ height: '100vh' }}>
      <Col flex={'400px'}>
        <Space direction={'vertical'} size={'small'} style={{ width: '100%' }}>
          <LogoWrapper>
            <img src={process.env.PUBLIC_URL + '/static/images/collect-logo.png'} alt={'Collect! Logo'} />
          </LogoWrapper>
          <Card size={'small'}>
            <Form onFinish={handleSubmit(onSubmit)}>
              {databaseConfig && databaseConfig.length > 0 && (
                <Form.Item label={'Database'}>
                  <Select
                    onSelect={(value: any) => {
                      setDbPickerValue(value)
                    }}
                    defaultValue={dbPickerValue}
                    style={{ width: '100%' }}
                  >
                    {databaseConfig.map((d: Record<string, any>) => (
                      <Select.Option value={d.value}>{d.label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Controller
                as={
                  <Form.Item
                    help={errors?.username?.message}
                    name={'username'}
                    validateStatus={errors?.username?.message ? 'error' : 'success'}
                  >
                    <Input
                      allowClear={true}
                      data-cy={'username'}
                      name={'username'}
                      placeholder={'Username'}
                      prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    />
                  </Form.Item>
                }
                name={'username'}
                control={control}
                defaultValue={null}
              />
              <Controller
                as={
                  <Form.Item
                    help={errors?.password?.message}
                    name={'password'}
                    validateStatus={errors?.password?.message ? 'error' : 'success'}
                  >
                    <Input.Password
                      allowClear={true}
                      data-cy={'password'}
                      name={'password'}
                      placeholder={'Password'}
                      prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25) ' }} />}
                    />
                  </Form.Item>
                }
                name={'password'}
                control={control}
                defaultValue={null}
              />
              <Button
                data-cy={'submit'}
                htmlType={'submit'}
                loading={AuthStore.meta.request.loading}
                style={{ width: '100%' }}
                type={'primary'}
              >
                Login
              </Button>
            </Form>
          </Card>
          {demoConfig && (
            <div>
              <Divider style={{ lineHeight: '5px', margin: '8px 0' }} />
              <Text strong>Use one of the following accounts to login:</Text>
              <Table
                bordered
                columns={tableData?.columns}
                dataSource={tableData?.data}
                size={'small'}
                pagination={false}
              />
            </div>
          )}
        </Space>
      </Col>
    </Row>
  )
})

export default LoginForm

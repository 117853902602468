import { castToSnapshot, flow, Instance, types } from 'mobx-state-tree'
import { actionFailure, actionStart, actionSuccess, FlowState, FlowStateEnum } from '../../../shared/stores'
import { createMetaModel, CreateMetaModelProps, MetaModel } from '../../../shared/stores/meta'
import { DashboardApiService } from '../services/api'

const ChartStore = types
  .model('ChartStore', {
    state: FlowStateEnum,
    payload: types.maybe(types.string),
    error: types.maybe(types.string),
    meta: MetaModel,
  })
  .actions((self) => {
    const api_getData = flow(function* () {
      actionStart(self)

      try {
        if (!self.meta.request.url) throw new Error('Url Error: url is required')

        const response = yield DashboardApiService.getChart(self.meta.request.url, self.meta.request.getRequestParams)
        const { result } = response.data

        actionSuccess(self)
        self.payload = JSON.stringify(result)

        return response
      } catch (error) {
        actionFailure(self, error)
        throw error
      }
    })

    return {
      api_getData,
    }
  })

export interface ChartStoreInstance extends Instance<typeof ChartStore> {}

interface CreateChartStoreProps {
  meta: CreateMetaModelProps
}

export const createChartStore = (props?: CreateChartStoreProps) => {
  return ChartStore.create({
    state: FlowState.IDLE,
    meta: castToSnapshot(createMetaModel(props?.meta)),
  })
}

import { observer } from 'mobx-react'
import React from 'react'
import { Route } from 'react-router-dom'
import { AuthStore } from '../auth/stores'
import ClientDashboard from './components/client'
import ManagerDashboard from './components/manager'
import OperatorPerformanceDashboard from './components/manager/OperatorPerformance'
import OperatorDashboard from './components/operator'

const DashboardContainer: React.FC = observer(() => {
  const renderDashboardForOperatorLevel = () => {
    let component = <React.Fragment></React.Fragment>

    if (AuthStore.isClient) {
      component = <ClientDashboard />
    } else if (AuthStore.isManager) {
      component = <ManagerDashboard />
    } else if (AuthStore.isOperator) {
      component = <OperatorDashboard />
    }

    return component
  }

  return (
    <React.Fragment>
      <Route exact path={'/dashboards'} component={() => renderDashboardForOperatorLevel()} />
      {AuthStore.isManager && (
        <Route path={'/dashboards/operator-performance'} component={OperatorPerformanceDashboard} />
      )}
    </React.Fragment>
  )
})

export default DashboardContainer

import { Typography } from 'antd'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const FlexContainer = styled.div`
  border: 1px solid #f0f2f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 4px;
`

const FlexTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  padding: 4px;
  text-align: center;
`

const FlexChildren = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface WidgetProps extends PropsWithChildren<any> {
  title?: string
}

const Widget: React.FC<WidgetProps> = ({ children, title, ...rest }: WidgetProps) => {
  const { Text } = Typography

  return title ? (
    <FlexContainer>
      <FlexTitle>
        <Text ellipsis strong>
          {title}
        </Text>
      </FlexTitle>
      <FlexChildren>{children}</FlexChildren>
    </FlexContainer>
  ) : (
    <FlexContainer>{children}</FlexContainer>
  )
}

export default Widget

import React from 'react'

interface LogoutWarningProps {
  warningTimeout: number
}

const LogoutWarning: React.FC<LogoutWarningProps> = (props: LogoutWarningProps) => {
  const millisecondsToMinutes = (milliseconds: number) => {
    const minutes = Math.floor(milliseconds / 6e4)
    const seconds = Number(((milliseconds % 6e4) / 1e3).toFixed(0))

    const minutesString = minutes === 1 ? 'minute' : 'minutes'
    const secondsString = seconds === 1 ? 'second' : 'seconds'

    return minutes > 0 ? `${minutes} ${minutesString}` : `${seconds} ${secondsString}`
  }

  return (
    <React.Fragment>
      <div>
        <strong>Logout Pending</strong>
      </div>
      <div>You have been idle for {millisecondsToMinutes(props.warningTimeout)} and will be logged out soon.</div>
    </React.Fragment>
  )
}

export default LogoutWarning

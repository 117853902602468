import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { ObjectLiteral } from '../../interfaces/object'
import { preloadRouteComponent } from './utils'

// Source: https://medium.com/maxime-heckel/react-lazy-a-take-on-preloading-views-cc90be869f14
interface PreloadLinkProps extends LinkProps {
  routes: ObjectLiteral[]
}

// Source: https://medium.com/maxime-heckel/react-lazy-a-take-on-preloading-views-cc90be869f14
export const PreloadLink: React.FC<PreloadLinkProps> = ({ routes, to, ...rest }: PreloadLinkProps) => {
  return <Link onMouseEnter={() => preloadRouteComponent(to as string, routes)} to={to} {...rest} />
}

import { IAnyModelType, Instance, types } from 'mobx-state-tree'
import { PaginatedResponseParams } from '../interfaces/response'
import { MetaModel } from './meta'

export const FlowState = {
  IDLE: 'IDLE',
  BEGIN: 'BEGIN',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export const FlowStateEnum = types.enumeration([FlowState.IDLE, FlowState.BEGIN, FlowState.SUCCESS, FlowState.FAILURE])

export const actionStart = (self: any) => {
  self.state = FlowState.BEGIN
  self.meta.request.loading = true
}

export const actionSuccess = (self: any, params?: PaginatedResponseParams) => {
  self.state = FlowState.SUCCESS
  self.error = undefined
  self.meta.request.loading = false

  if (params) {
    const { count, limit, page, pageCount } = params

    self.meta.response.pagination.count = count
    self.meta.response.pagination.limit = limit
    self.meta.response.pagination.page = page
    self.meta.response.pagination.pageCount = pageCount
  }
}

export const actionFailure = (self: any, error: Error) => {
  self.state = FlowState.FAILURE
  self.error = error.message
  self.meta.request.loading = false
}

export const ListStore = (name: string) => {
  return types
    .model(name, {
      state: FlowStateEnum,
      payload: types.array(types.string),
      error: types.maybe(types.string),
      meta: MetaModel
    })
    .actions((self) => {
      function api_getList(): Promise<void> {
        throw Error('api_getList() must be implemented in child store')
      }

      const clearPayload = () => self.payload.clear()

      return {
        api_getList,
        clearPayload
      }
    })
}

export interface ListStoreInstance extends Instance<typeof ListStore> {}

export const ObjectStore = (name: string, model: IAnyModelType) => {
  return types
    .model(name, {
      state: FlowStateEnum,
      payload: types.map(model),
      error: types.maybe(types.string),
      meta: MetaModel
    })
    .actions((self) => {
      function api_createObject(data: any): Promise<void> {
        throw Error('api_createObject() must be implemented in child store')
      }

      function api_getObject(key: string): Promise<void> {
        throw Error('api_getObject() must be implemented in child store')
      }

      function api_updateObject(key: string): Promise<void> {
        throw Error('api_updateObject() must be implemented in child store')
      }

      function api_deleteObject(key: string): Promise<void> {
        throw Error('api_deleteObject() must be implemented in child store')
      }

      const clearPayload = () => self.payload.clear()

      const saveObject = (object: any) => {
        throw Error('saveObject() must be implemented in child store')
      }

      return {
        api_createObject,
        api_getObject,
        api_updateObject,
        api_deleteObject,
        clearPayload,
        saveObject
      }
    })
}

export interface ObjectStoreInstance extends Instance<typeof ObjectStore> {}

import { DownloadOutlined } from '@ant-design/icons'
import { Button, List, Popover, Typography } from 'antd'
import React from 'react'
import { CSVLink } from 'react-csv'
import { v4 as uuidv4 } from 'uuid'
import { CSVHeaderInterface } from '../../interfaces/csv-header.interface'

interface DownloadButtonItem {
  label: string
  data: Record<string, any>[]
  headers: CSVHeaderInterface[];
}

export interface DownloadButtonProps {
  csvItems: DownloadButtonItem[]
}

const DownloadButton = (props: DownloadButtonProps) => {
  const { Text } = Typography

  return (
    <Popover
      content={
        <div style={{ width: '250px' }}>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Text italic strong type="secondary">
              CSV Downloads
            </Text>
            <br />
            <Text italic type="secondary">
              Incrase page size to include more data
            </Text>
          </div>
          <List
            itemLayout="vertical"
            size="small"
            dataSource={props.csvItems}
            renderItem={(item) => (
              <List.Item
                key={item.label}
                style={{ textAlign: 'right', paddingTop: '4px', paddingRight: '0px', paddingBottom: '4px' }}
              >
                <CSVLink data={item.data} filename={`${uuidv4()}.csv`} headers={item.headers}>
                  {item.label}
                </CSVLink>
              </List.Item>
            )}
          />
        </div>
      }
      placement={'bottomRight'}
      trigger={'click'}
    >
      <Button data-cy={`filter-bar-refresh`} type="default">
        <Text style={{ color: '#8C8C8C' }}>Download </Text>
        <DownloadOutlined />
      </Button>
    </Popover>
  )
}

export default DownloadButton

import { Space } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Helmet } from 'react-helmet-async'
import FilterBar from '../../../../shared/components/FilterBar'
import CollectPageHeader from '../../../../shared/components/PageHeader'
import { AuthStore } from '../../../auth/stores'
import { NxUserStore } from '../../../collect/stores/nx-user'
import { createChartStore } from '../../stores/chart'
import CollectionsByOperator from '../charts/CollectionsByOperator'
import CollectionsHistory from '../charts/CollectionsHistory'
import ContactSummary from '../charts/ContactSummary'
import OverallSummary from '../charts/OverallSummary'
import Widget from '../shared/Widget'

const RGL = WidthProvider(Responsive)

const ManagerDashboard: React.FC = observer(() => {
  const [contactSummaryCompanyStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/manager/contact-summary` } }
    })
  })

  const [contactSummaryOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/operator/contact-summary` } }
    })
  })

  const [fiveDayCollectionsByCompanyStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/manager/five-day-collections` } }
    })
  })

  const [fiveDayCollectionsByOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/operator/five-day-collections` } }
    })
  })

  const [overallSummaryCompanyStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/manager/overall-summary` } }
    })
  })

  const [overallSummaryOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/operator/overall-summary` } }
    })
  })

  const [topFiveCollectionsByOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/manager/operators-top-five` } }
    })
  })

  const [bottomFiveCollectionsByOperatorStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: `/api/v1/dashboard/dashboard/manager/operators-bottom-five` } }
    })
  })

  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }
  const cols = { lg: 6, md: 6, sm: 6, xs: 3, xxs: 3 }
  const layouts = {
    lg: [
      { i: 'company-cctr', x: 0, y: 0, w: 3, h: 1, static: true },
      { i: 'operator-cctr', x: 4, y: 0, w: 3, h: 1, static: true },
      { i: 'company-contacts', x: 0, y: 1, w: 1, h: 2, static: true },
      { i: 'company-collections', x: 1, y: 1, w: 2, h: 2, static: true },
      { i: 'operator-contacts', x: 3, y: 1, w: 1, h: 2, static: true },
      { i: 'operator-collections', x: 4, y: 1, w: 2, h: 2, static: true },
      { i: 'top-operators', x: 0, y: 3, w: 3, h: 2, static: true },
      { i: 'bottom-operators', x: 3, y: 3, w: 3, h: 2, static: true }
    ],
    xs: [
      { i: 'company-cctr', x: 0, y: 0, w: 3, h: 1, static: true },
      { i: 'company-contacts', x: 0, y: 1, w: 3, h: 2, static: true },
      { i: 'company-collections', x: 0, y: 3, w: 3, h: 2, static: true },
      { i: 'top-operators', x: 0, y: 5, w: 3, h: 2, static: true },
      { i: 'bottom-operators', x: 0, y: 7, w: 3, h: 2, static: true },
      { i: 'operator-cctr', x: 0, y: 9, w: 3, h: 1, static: true },
      { i: 'operator-contacts', x: 0, y: 10, w: 3, h: 2, static: true },
      { i: 'operator-collections', x: 0, y: 12, w: 3, h: 2, static: true }
    ]
  }

  const companyName = NxUserStore.payload?.us_name ?? ''

  return (
    <Space direction={'vertical'} size={'small'} style={{ width: '100%' }}>
      <Helmet title={'Manager Dashboard'} />

      <CollectPageHeader title={'Manager Dashboard'} />
      <FilterBar
        showRefresh={true}
        stores={[
          contactSummaryCompanyStore,
          contactSummaryOperatorStore,
          fiveDayCollectionsByCompanyStore,
          fiveDayCollectionsByOperatorStore,
          overallSummaryCompanyStore,
          overallSummaryOperatorStore,
          topFiveCollectionsByOperatorStore,
          bottomFiveCollectionsByOperatorStore
        ]}
      />
      <RGL breakpoints={breakpoints} cols={cols} containerPadding={[0, 0]} layouts={layouts} rowHeight={100}>
        <div key={'company-cctr'}>
          <Widget>
            <OverallSummary store={overallSummaryCompanyStore} label={companyName} type={'manager'} />
          </Widget>
        </div>
        <div key={'operator-cctr'}>
          <Widget>
            <OverallSummary
              store={overallSummaryOperatorStore}
              label={AuthStore.payload?.nx_operator?.op_name}
              type={'operator'}
            />
          </Widget>
        </div>
        <div key={'company-contacts'}>
          <Widget title={`Contacts ${companyName.length > 0 ? `for ${companyName}` : ''}`}>
            <ContactSummary store={contactSummaryCompanyStore} type={'manager'} />
          </Widget>
        </div>
        <div key={'company-collections'}>
          <Widget title={`Five Day Collections ${companyName.length > 0 ? `for ${companyName}` : ''}`}>
            <CollectionsHistory store={fiveDayCollectionsByCompanyStore} type={'manager'} />
          </Widget>
        </div>
        <div key={'operator-contacts'}>
          <Widget title={`Contacts for ${AuthStore.payload?.nx_operator?.op_name}`}>
            <ContactSummary store={contactSummaryOperatorStore} type={'operator'} />
          </Widget>
        </div>
        <div key={'operator-collections'}>
          <Widget
            title={`Five Day Collections ${
              AuthStore.payload?.nx_operator?.op_name && `for ${AuthStore.payload.nx_operator.op_name}`
            }`}
          >
            <CollectionsHistory store={fiveDayCollectionsByOperatorStore} type={'operator'} />
          </Widget>
        </div>
        <div key={'top-operators'}>
          <Widget title={'Top Five Operators for Today'}>
            <CollectionsByOperator store={topFiveCollectionsByOperatorStore} type={'top'} />
          </Widget>
        </div>
        <div key={'bottom-operators'}>
          <Widget title={'Bottom Five Operators for Today'}>
            <CollectionsByOperator store={bottomFiveCollectionsByOperatorStore} type={'bottom'} />
          </Widget>
        </div>
      </RGL>
    </Space>
  )
})

export default ManagerDashboard

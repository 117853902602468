import { RequestParams } from '../../../../shared/interfaces/request'
import { createRequestUrl } from '../../../../shared/utils/request'
import { CollectApiClient } from '../../../collect/services/api/client'

const getPicklist = (scope: string, key: string, value: string, extra?: string, params?: RequestParams) => {
  let extraObj: Record<string, any> = {
    key: key,
    value: value
  }

  if (extra) extraObj['extra'] = extra

  return CollectApiClient({
    method: 'GET',
    url: createRequestUrl(`/api/v1/dashboard/picklists/${scope}`, params, extraObj)
  })
}

export const PicklistApiService = {
  getPicklist
}

import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Popover, Row, Space, Typography } from 'antd'
import { observer } from 'mobx-react'
import moment, { Moment } from 'moment'
import React from 'react'
import { FilterBarStoreInstance } from '../../stores'

interface DateFilterProps {
  filterBarStore: FilterBarStoreInstance
  label: string
  name: string
  pickerProps: {
    picker: 'month'
  }
}

const DateFilter: React.FC<DateFilterProps> = observer((props: DateFilterProps) => {
  const { Text } = Typography

  const filterValuesExist = props.filterBarStore.filters.get(`${props.name}__eq`)?.value
  const defaultValues = () => {
    return filterValuesExist ? moment(props.filterBarStore.filters.get(`${props.name}__eq`)?.value) : undefined
  }

  return (
    <Popover
      content={
        <Space direction={'vertical'}>
          <Row>
            <Col flex={1}>
              <Text strong>{props.label}</Text>
            </Col>
            <Col flex={0} style={{ textAlign: 'right' }}>
              {filterValuesExist && (
                <Button
                  onClick={() => {
                    props.filterBarStore.addFilter({
                      field: Array.isArray(props.name) ? props.name[0] : props.name,
                      operation: 'eq',
                      value: undefined
                    })
                  }}
                  size={'small'}
                  type={'link'}
                >
                  <CloseOutlined /> Clear
                </Button>
              )}
            </Col>
          </Row>
          <DatePicker
            onChange={(value: Moment | null, dateString: string) => {
              // const startDate = value?.clone().startOf('month').format('YYYY-MM-DD')
              let startDate: string | undefined

              startDate = value?.clone().endOf('month').format('YYYY-MM-DD')

              props.filterBarStore.addFilter({
                field: props.name,
                operation: 'eq',
                value: startDate
              })
            }}
            name={props.name}
            defaultValue={defaultValues()}
            value={defaultValues()}
            picker={props.pickerProps.picker}
          />
        </Space>
      }
      placement={'bottomLeft'}
      trigger={'click'}
    >
      <Button data-cy={`filter-bar-filters-date-${props.name}`} type={filterValuesExist ? 'primary' : 'default'}>
        <Space size={'small'}>
          <span>
            <Text style={{ color: filterValuesExist ? '#F2F2F2' : '#8C8C8C' }}>{props.label}: </Text>
            <Text strong style={{ color: filterValuesExist ? '#F2F2F2' : '#000' }}>
              {filterValuesExist
                ? moment(props.filterBarStore.filters.get(`${props.name}__eq`)?.value).format('MMM YYYY')
                : 'All'}
            </Text>
          </span>
          <DownOutlined />
        </Space>
      </Button>
    </Popover>
  )
})

export default DateFilter

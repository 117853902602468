import { Col, Empty, Row } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Spinner from '../../../../../shared/components/Spinner'
import { ObjectLiteral } from '../../../../../shared/interfaces/object'
import { getCurrencyFormat } from '../../../../../shared/utils/intl'
import { ChartStoreInstance } from '../../../stores/chart'

interface ThreeMonthCollectionSummaryProps {
  store: ChartStoreInstance
}

const ThreeMonthCollectionSummary: React.FC<ThreeMonthCollectionSummaryProps> = observer(
  (props: ThreeMonthCollectionSummaryProps) => {
    const { store } = props

    const intl = useIntl()

    useEffect(() => {
      if (!props.store) {
        store.api_getData()
      }
    }, [])

    const getHighestWorkingDay = (
      currMonthData: ObjectLiteral,
      lastMonthData: ObjectLiteral,
      prevMonthData: ObjectLiteral
    ) => {
      let max: number = 0

      for (let i = 31; i >= 1; i--) {
        if (currMonthData[i] !== null || lastMonthData[i] !== null || prevMonthData[i] !== null) {
          max = i
          break
        }
      }

      return max
    }

    const dataSource = (): ObjectLiteral[] => {
      const data: ObjectLiteral[] = []

      if (store.payload) {
        const arr: ObjectLiteral[] = JSON.parse(store.payload)

        const curr = arr.find((obj) => obj.month === 'curr') ?? []
        const last = arr.find((obj) => obj.month === 'last') ?? []
        const prev = arr.find((obj) => obj.month === 'prev') ?? []

        const highestWorkingDay = getHighestWorkingDay(curr, last, prev)

        // Delete values from object
        Object.keys(curr).forEach((key) => curr[key] === null && delete curr[key])
        Object.keys(last).forEach((key) => last[key] === null && delete last[key])
        Object.keys(prev).forEach((key) => prev[key] === null && delete prev[key])

        const { month: currMonth, ...currRest } = curr
        const { month: lastMonth, ...lastRest } = last
        const { month: prevMonth, ...prevRest } = prev

        for (let i = 1; i <= highestWorkingDay; i++) {
          data.push({
            working_day: i,
            curr_sum_collected: currRest[i],
            last_sum_collected: lastRest[i],
            prev_sum_collected: prevRest[i]
          })
        }
      }

      return data
    }

    return store.meta.request.loading ? (
      <Spinner active={store.meta.request.loading} />
    ) : dataSource()?.length > 0 ? (
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}>
          <ResponsiveContainer>
            <LineChart data={dataSource()}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={'working_day'} />
              <YAxis
                tickFormatter={(value) => intl.formatNumber(value ?? 0, getCurrencyFormat({ notation: 'compact' }))}
                width={40}
              />
              <Tooltip
                labelFormatter={(label: any) => {
                  return `Day of Month: ${label}`
                }}
                formatter={(value: any, name: any, item: any) => {
                  switch (item.dataKey) {
                    case 'curr_sum_collected':
                    case 'last_sum_collected':
                    case 'prev_sum_collected': {
                      return intl.formatNumber(value, getCurrencyFormat())
                    }
                    default:
                      return value
                  }
                }}
              />
              <Legend />
              <Line
                dataKey={'curr_sum_collected'}
                name={'Current Month Collected'}
                stroke={'#F89939'}
                type={'monotone'}
              />
              <Line dataKey={'last_sum_collected'} name={'Last Month Collected'} stroke={'#1A3373'} type={'monotone'} />
              <Line
                dataKey={'prev_sum_collected'}
                name={'Previous Month Collected'}
                stroke={'#2BA3BB'}
                type={'monotone'}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    ) : (
      <Row align={'middle'} justify={'center'} style={{ height: '100%' }}>
        <Col>
          <Empty />
        </Col>
      </Row>
    )
  }
)

export default ThreeMonthCollectionSummary

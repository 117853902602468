import { CloseOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Input, List, Popover, Row, Space, Typography } from 'antd'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import React, { useEffect, useMemo, useState } from 'react'
import { List as VList, ListRowProps as VListRowProps } from 'react-virtualized'
import { createPicklistStore, PicklistOptionModelInstance } from '../../../../../modules/picklist/stores'
import { RequestFilterModelInstance } from '../../../../stores/meta'
import Spinner from '../../../Spinner'
import { FilterBarStoreInstance } from '../../stores'

interface DynamicPicklistFilterProps {
  filterBarStore: FilterBarStoreInstance
  label: string
  name: string
  storeProps: {
    scope: string
    key: string
    value: string
    extra?: string
    filters?: { [key: string]: RequestFilterModelInstance }
  }
}

const DynamicPicklistFilter: React.FC<DynamicPicklistFilterProps> = observer((props: DynamicPicklistFilterProps) => {
  const [initialValuesMapped, setInitialValuesMapped] = useState<boolean>(false)

  const picklistStore = useMemo(
    () =>
      createPicklistStore({
        meta: {
          request: {
            loading: false,
            args: {
              scope: { key: 'scope', value: props.storeProps.scope },
              key: { key: 'key', value: props.storeProps.key },
              value: { key: 'value', value: props.storeProps.value },
              extra: { key: 'extra', value: props.storeProps.extra }
            },
            filters: props.storeProps.filters
          }
        }
      }),
    []
  )
  const { Text } = Typography

  const filterValueExists = props.filterBarStore.filters.get(`${props.name}__in`)?.value ? true : false
  const filter = props.filterBarStore.filters.get(`${props.name}__in`)

  useEffect(() => {
    picklistStore.api_getPicklist()
  }, [])

  useEffect(() => {
    if (filterValueExists && picklistStore.selectedOptions.size === 0 && !initialValuesMapped) {
      filter?.value
        ?.split(',')
        .forEach((key) => picklistStore.toggleSelectedOption({ key: key, value: '', extra: undefined }))
      setInitialValuesMapped(true)
    }
  }, [filter, filterValueExists, initialValuesMapped, picklistStore])

  return (
    <Popover
      content={
        <Space direction={'vertical'} size={'small'}>
          <Row>
            <Col flex={1}>
              <Text strong>{props.label}</Text>
            </Col>
            <Col flex={0}>
              {filterValueExists && (
                <Button
                  onClick={() => {
                    picklistStore.clearSelectedOptions()
                    props.filterBarStore.addFilter({ field: props.name, operation: 'in', value: undefined })
                  }}
                  size={'small'}
                  type={'link'}
                >
                  <CloseOutlined /> Clear
                </Button>
              )}
            </Col>
          </Row>
          <Input.Search
            onSearch={(value: string) => {
              picklistStore.meta.request.setSearch(value)
              picklistStore.api_getPicklist()
            }}
            allowClear
            placeholder={'Search...'}
          />
          <VList
            height={200}
            width={300}
            noRowsRenderer={() => (picklistStore.meta.request.loading ? <Spinner /> : <React.Fragment />)}
            overscanRowCount={10}
            // When picklistStore.meta.request.loading === true, render 0 rows
            // This allows us to show the loading spinner defined in noRowsRenderer()
            rowCount={picklistStore.meta.request.loading ? 0 : picklistStore.options.size}
            rowHeight={32}
            // We use the data prop to force re-render when the picklistStore.selectedoptions change
            data={picklistStore.selectedOptions.size}
            rowRenderer={(rowProps: VListRowProps) => {
              const option: PicklistOptionModelInstance | undefined = picklistStore.options.get(
                Array.from(picklistStore.options.keys())[rowProps.index]
              )

              return (
                <List.Item
                  onClick={() => {
                    if (option) {
                      picklistStore.toggleSelectedOption(getSnapshot(option))
                      const selectedOptionKeys =
                        Array.from(picklistStore.selectedOptions.values()).length > 0
                          ? Array.from(picklistStore.selectedOptions.values())
                              .map((v) => v.key)
                              .join(',')
                          : undefined
                      props.filterBarStore.addFilter({ field: props.name, operation: 'in', value: selectedOptionKeys })
                    }
                  }}
                  key={rowProps.index}
                  style={{
                    ...rowProps.style
                  }}
                >
                  <Space direction={'horizontal'} size={'small'}>
                    <Checkbox checked={option?.key && picklistStore.selectedOptions.get(option.key) ? true : false} />
                    {option?.value}
                    {option?.extra && <span style={{ color: 'silver' }}>{option?.extra}</span>}
                  </Space>
                </List.Item>
              )
            }}
          />
        </Space>
      }
      placement={'bottomLeft'}
      trigger={'click'}
    >
      <Button
        data-cy={`filter-bar-filters-dynamic-picklist-${props.name}`}
        type={filterValueExists ? 'primary' : 'default'}
      >
        <Space size={'small'}>
          <span>
            <Text style={{ color: filterValueExists ? '#F2F2F2' : '#8C8C8C' }}>{props.label}: </Text>
            <Text strong style={{ color: filterValueExists ? '#F2F2F2' : '#000' }}>
              {filterValueExists ? `${filter?.value?.split(',').length} Values` : 'All'}
            </Text>
          </span>
          <DownOutlined />
        </Space>
      </Button>
    </Popover>
  )
})

export default DynamicPicklistFilter

import { DashboardOutlined, DatabaseOutlined, EditOutlined, FileSearchOutlined, UserOutlined } from '@ant-design/icons'
import { Badge, Col, Menu, Row } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { PreloadLink } from '../../../../shared/components/PreloadLink'
import { ObjectLiteral } from '../../../../shared/interfaces/object'
import AccountStore from '../../../account/stores'
import { AuthStore } from '../../../auth/stores'
import { NxUserStore } from '../../../collect/stores/nx-user'
import LogoWrapper from './LogoWrapper'

interface CoreMenuProps {
  routes: ObjectLiteral[]
}

const CoreMenu: React.FC<CoreMenuProps> = observer((props: CoreMenuProps) => {
  const { Divider, Item, SubMenu } = Menu

  const renderSubMenu = (key: string, label: String, children?: React.ReactNode[], icon?: React.ReactElement) => {
    return (
      <SubMenu
        data-cy={key}
        key={key}
        title={
          <span className={'submenu-title-wrapper'}>
            {icon}
            {label}
          </span>
        }
      >
        {children}
      </SubMenu>
    )
  }

  const renderMenuItem = (key: string, to: string, label: string) => {
    return (
      <Item data-cy={key} key={key}>
        <PreloadLink routes={props.routes} to={to}>
          {label}
        </PreloadLink>
      </Item>
    )
  }

  const renderAuditLogsMenu = () => {
    const clientMenuItems = [
      { key: 'menu-audit-logs-client-clients', to: '/audit-logs/client/clients', label: 'Clients' },
      { key: 'menu-audit-logs-client-attachments', to: '/audit-logs/client/cl-attachments', label: 'Attachments' },
      { key: 'menu-audit-logs-client-contacts', to: '/audit-logs/client/cl-contacts', label: 'Contacts' },
      { key: 'menu-audit-logs-client-invoices', to: '/audit-logs/client/invoices', label: 'Invoices' },
      { key: 'menu-audit-logs-client-invoice-lines', to: '/audit-logs/client/invoice-lines', label: 'Invoice Lines' },
      { key: 'menu-audit-logs-client-transactions', to: '/audit-logs/client/cl-transactions', label: 'Transactions' }
    ]
    const debtorMenuItems = [
      { key: 'menu-audit-logs-debtor-debtors', to: '/audit-logs/debtor/debtors', label: 'Debtors' },
      { key: 'menu-audit-logs-debtor-attachments', to: '/audit-logs/debtor/attachments', label: 'Attachments' },
      { key: 'menu-audit-logs-debtor-contacts', to: '/audit-logs/debtor/contacts', label: 'Contacts' },
      { key: 'menu-audit-logs-debtor-cosigners', to: '/audit-logs/debtor/cosigners', label: 'Cosigners' },
      { key: 'menu-audit-logs-debtor-phones', to: '/audit-logs/debtor/phones', label: 'Phones' },
      { key: 'menu-audit-logs-debtor-transactions', to: '/audit-logs/debtor/de-transactions', label: 'Transactions' }
    ]
    const systemMenuItems = [
      {
        key: 'menu-audit-logs-system-application-forms',
        to: '/audit-logs/system/application-forms',
        label: 'Application Forms'
      },
      { key: 'menu-audit-logs-system-blobs', to: '/audit-logs/system/blobs', label: 'Blobs' },
      {
        key: 'menu-audit-logs-system-contact-events',
        to: '/audit-logs/system/contact-events',
        label: 'Contact Events'
      },
      { key: 'menu-audit-logs-system-contact-plans', to: '/audit-logs/system/contact-plans', label: 'Contact Plans' },
      {
        key: 'menu-audit-logs-system-debtor-statuses',
        to: '/audit-logs/system/debtor-statuses',
        label: 'Debtor Statuses'
      },
      { key: 'menu-audit-logs-system-form-rights', to: '/audit-logs/system/form-rights', label: 'Form Rights' },
      ...(NxUserStore.payload?.us_country === 'AU'
        ? [
            {
              key: 'menu-audit-logs-internal-disputes',
              to: '/audit-logs/system/internal-disputes',
              label: 'Internal Disputes'
            }
          ]
        : []),
      { key: 'menu-audit-logs-system-nx-days', to: '/audit-logs/system/nx-days', label: 'NX Days' },
      { key: 'menu-audit-logs-system-nx-operators', to: '/audit-logs/system/nx-operators', label: 'NX Operators' },
      { key: 'menu-audit-logs-system-nx-users', to: '/audit-logs/system/nx-users', label: 'NX Users' },
      {
        key: 'menu-audit-logs-system-operator-levels',
        to: '/audit-logs/system/operator-levels',
        label: 'Operator Levels'
      },
      {
        key: 'menu-audit-logs-system-rate-tables',
        to: '/audit-logs/system/rate-tables',
        label: 'Rate Table'
      },
      {
        key: 'menu-audit-logs-system-transaction-types',
        to: '/audit-logs/system/transaction-types',
        label: 'Transaction Types'
      }
    ]

    return (
      <SubMenu
        data-cy={'menu-audit-logs'}
        key={'audit-logs'}
        title={
          <span className={'submenu-title-wrapper'}>
            <DatabaseOutlined />
            Audit Logs
          </span>
        }
      >
        {renderSubMenu(
          'menu-audit-logs-client',
          'Client',
          clientMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))
        )}
        {renderSubMenu(
          'menu-audit-logs-debtor',
          'Debtor',
          debtorMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))
        )}
        {renderSubMenu(
          'menu-audit-logs-system',
          'System',
          systemMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))
        )}
      </SubMenu>
    )
  }

  const renderReportsMenu = () => {
    const clientMenuItems = [
      { key: 'menu-reports-client-client-listed', to: '/reports/client/client-listed', label: 'Client Listed' },
      {
        key: 'menu-reports-client-client-transactions',
        to: '/reports/client/client-transactions',
        label: 'Client Transactions'
      },
      { key: 'menu-reports-client-dated-accounts', to: '/reports/client/dated-accounts', label: 'Dated Accounts' },
      { key: 'menu-reports-client-listed-analysis', to: '/reports/client/listed-analysis', label: 'Listed Analysis' },
      { key: 'menu-reports-client-work-queue', to: '/reports/client/work-queue', label: 'Work Queue' }
    ]

    const managerMenuItems = [
      {
        key: 'menu-reports-manager-client-analysis',
        to: '/reports/manager/client-analysis',
        label: 'Client Analysis'
      },
      {
        key: 'menu-reports-manager-client-placements',
        to: '/reports/manager/client-placements',
        label: 'Client Placement'
      },
      {
        key: 'menu-reports-manager-company-collections',
        to: '/reports/manager/company-collections',
        label: 'Company Collections'
      },
      { key: 'menu-reports-manager-listed-by', to: '/reports/manager/listed-by-region', label: 'Listed by Region' },
      {
        key: 'menu-reports-manager-operator-activity',
        to: '/reports/manager/operator-activity',
        label: 'Operator Activity'
      },
      {
        key: 'menu-reports-manager-payment-forecasts',
        to: '/reports/manager/payment-forecasts',
        label: 'Payment Forecast'
      },
      { key: 'menu-reports-manager-spindown', to: '/reports/manager/spindown', label: 'Spindown' }
    ]

    const operatorMenuItems = [
      {
        key: 'menu-reports-operator-operator-collections',
        to: '/reports/operator/operator-collections',
        label: 'Operator Collections'
      },
      {
        key: 'menu-reports-operator-operator-inventory',
        to: '/reports/operator/operator-inventory',
        label: 'Operator Inventory'
      }
    ]

    return (
      <SubMenu
        data-cy={'menu-reports'}
        key={'reports'}
        title={
          <span className={'submenu-title-wrapper'}>
            <FileSearchOutlined />
            Reports
          </span>
        }
      >
        {/* Account Inquiry */}
        <Item data-cy={'menu-reports-account-inquiry'} key={'reports-account-inquiry'}>
          <PreloadLink routes={props.routes} to={'/reports/account-inquiry'}>
            Account Inquiry
          </PreloadLink>
        </Item>
        <Divider />
        {AuthStore.isManager &&
          renderSubMenu(
            'menu-reports-client',
            'Client',
            clientMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))
          )}
        {AuthStore.isManager &&
          renderSubMenu(
            'menu-reports-manager',
            'Manager',
            managerMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))
          )}
        {AuthStore.isManager &&
          renderSubMenu(
            'menu-reports-operator',
            'Operator',
            operatorMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))
          )}
        {AuthStore.isClient && clientMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))}
        {AuthStore.isOperator && operatorMenuItems.map(({ key, to, label }) => renderMenuItem(key, to, label))}
      </SubMenu>
    )
  }

  return (
    <Menu
      forceSubMenuRender={true}
      mode={'horizontal'}
      style={{
        background: 'transparent',
        borderBottom: 'none',
        lineHeight: '64px'
      }}
      theme={'light'}
    >
      {/* Logo */}
      <Item
        data-cy={'menu-logo'}
        key={'logo'}
        style={{ cursor: 'default', padding: 0, borderBottom: '2px solid transparent' }}
      >
        <Link to={'/'}>
          <LogoWrapper>
            <img src={process.env.PUBLIC_URL + '/static/images/collect-logo.png'} alt={'Collect! Logo'} />
          </LogoWrapper>
        </Link>
      </Item>
      {/* Dashboards */}
      {AuthStore.isManager ? (
        <SubMenu
          data-cy={'menu-dashboard'}
          key={'dashboard'}
          title={
            <span className={'submenu-title-wrapper'}>
              <DashboardOutlined />
              Dashboard
            </span>
          }
        >
          <Item data-cy={'menu-dashboard-manager'} key={'dashboard-manager'}>
            <Link to={'/'}>Manager</Link>
          </Item>
          <Item data-cy={'menu-dashboard-operator-performance'} key="dashboard-operator-performance">
            <Link to={'/dashboards/operator-performance'}>Operator Performance</Link>
          </Item>
        </SubMenu>
      ) : (
        <Item data-cy={'menu-dashboard'} key={'dashboard'}>
          <DashboardOutlined />
          <PreloadLink routes={props.routes} to={'/dashboards'}>
            Dashboard
          </PreloadLink>
        </Item>
      )}
      {/* Reports */}
      {renderReportsMenu()}
      {/* Compliance */}
      {(AuthStore.isManager || (AuthStore.isOperator && NxUserStore.payload?.us_country === 'AU')) && (
        <SubMenu
          data-cy={'menu-compliance'}
          key={'compliance'}
          title={
            <span className={'submenu-title-wrapper'}>
              <EditOutlined />
              Compliance
            </span>
          }
        >
          {AuthStore.isManager && (
            <Item data-cy={'menu-compliance-documents'} key={'compliance-documents'}>
              <Link to={'/compliance/documents'}>Documents</Link>
            </Item>
          )}
          {NxUserStore.payload?.us_country === 'AU' && (
            <Item data-cy={'menu-compliance-internal-disputes'} key={'compliance-internal-disputes'}>
              <Link to={'/compliance/internal-disputes'}>Internal Disputes</Link>
            </Item>
          )}
        </SubMenu>
      )}
      {/* Audit Log Menu */}
      {AuthStore.isManager && renderAuditLogsMenu()}
      {/* Account Menu */}
      <SubMenu
        data-cy={'menu-account'}
        key={'account'}
        style={{
          marginLeft: 'auto'
        }}
        title={
          <Badge count={AccountStore.notifications.payload?.account ?? 0} size={'small'}>
            <span className={'submenu-title-wrapper'}>
              <UserOutlined />
              Account
            </span>
          </Badge>
        }
      >
        <Item data-cy={'menu-account-my-documents'} key={'account-my-documents'}>
          <Row>
            <Col flex={1}>
              <Link to={'/account/documents'}>My Documents</Link>
            </Col>
            <Col flex={0}>
              <Badge count={AccountStore.notifications.payload?.account_my_documents ?? 0} size={'small'} />
            </Col>
          </Row>
        </Item>
        <Divider />
        <SubMenu
          data-cy={'menu-account-links'}
          key={'account-links'}
          title={<span className={'submenu-title-wrapper'}>Links</span>}
        >
          <Item data-cy={'menu-account-links-help'} key="account-links-help">
            <a href={'https://www.collect.org/cv12/Help/contents.html'} target={'_blank'} rel={'noopener noreferrer'}>
              Help
            </a>
          </Item>
          <Item data-cy={'menu-account-links-member-center'} key={'account-links-member-center'}>
            <a href={'https://www.collect.org/member-login.html'} target={'_blank'} rel={'noopener noreferrer'}>
              Member Center
            </a>
          </Item>
          <Item data-cy={'menu-account-links-contact-us'} key={'account-links-contact-us'}>
            <a href={'https://www.collect.org/contact-us.html'} target={'_blank'} rel={'noopener noreferrer'}>
              Contact Us
            </a>
          </Item>
        </SubMenu>
        <Divider />
        <Item data-cy={'menu-account-logout'} key={'account-logout'}>
          <Link to={'/auth/logout'}>Logout</Link>
        </Item>
      </SubMenu>
    </Menu>
  )
})

export default CoreMenu

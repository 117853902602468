import { castToSnapshot, Instance, types } from 'mobx-state-tree'
import { FlowState, FlowStateEnum } from '../../../../../shared/stores'
import { createMetaModel, CreateMetaModelProps, MetaModel } from '../../../../../shared/stores/meta'

const NotificationModel = types.model('NotificationModel', {
  account: types.number,
  account_my_documents: types.number
})

export interface NotificationModelInstance extends Instance<typeof NotificationModel> {}

export const NotificationStore = types
  .model('NotificationStore', {
    state: FlowStateEnum,
    payload: types.maybe(NotificationModel),
    error: types.maybe(types.string),
    meta: MetaModel
  })
  .actions((self) => {
    return {}
  })

export interface NotificationStoreInstance extends Instance<typeof NotificationStore> {}

interface CreateNotificationStoreProps {
  meta: CreateMetaModelProps
}

export const createNotificationStore = (props?: CreateNotificationStoreProps) => {
  return NotificationStore.create({
    state: FlowState.IDLE,
    meta: castToSnapshot(createMetaModel(props?.meta))
  })
}

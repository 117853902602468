import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useIdle } from 'react-use'
import { RouterStore } from '../../../../shared/stores/router'
import LogoutWarning from './LogoutWarning'

interface IdleTimerProps {
  warningTimeout?: number
  logoutTimeout?: number
}

const IdleTimer: React.FC<IdleTimerProps> = observer(
  ({ warningTimeout = 25e3 * 60, logoutTimeout = 30e3 * 60 }: IdleTimerProps) => {
    const [idleNotificationId, setIdleNotificationId] = useState<void>()
    const { addToast, removeToast } = useToasts()

    const needsWarning = useIdle(warningTimeout)
    const needsLogout = useIdle(logoutTimeout)

    useEffect(() => {
      if (needsWarning) {
        const notificationId = addToast(<LogoutWarning warningTimeout={warningTimeout} />, { appearance: 'warning' })
        setIdleNotificationId(notificationId)
      } else {
        if (idleNotificationId) {
          removeToast(idleNotificationId as string, () => setIdleNotificationId(undefined))
        }
      }
    }, [needsWarning])

    useEffect(() => {
      if (needsLogout) {
        if (idleNotificationId) {
          removeToast(idleNotificationId as string, () => setIdleNotificationId(undefined))
        }
        RouterStore.router.push('/auth/logout')
      }
    }, [needsLogout])

    return <React.Fragment></React.Fragment>
  }
)

export default IdleTimer

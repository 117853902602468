import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { ChartProps } from '.'
import Spinner from '../../../../shared/components/Spinner'
import { ObjectLiteral } from '../../../../shared/interfaces/object'
import { getCurrencyFormat } from '../../../../shared/utils/intl'
import { ChartStoreInstance } from '../../stores/chart'

export interface CollectionsHistoryProps extends ChartProps {
  store: ChartStoreInstance
  type: 'client' | 'manager' | 'operator'
}

const CollectionsHistory: React.FC<CollectionsHistoryProps> = observer((props: CollectionsHistoryProps) => {
  const intl = useIntl()
  const { store } = props

  useEffect(() => {
    store.api_getData()
  }, [])

  const dataSource = (): ObjectLiteral[] | undefined =>
    store.payload
      ? JSON.parse(store.payload)
          .sort((a: Record<string, any>, b: Record<string, any>) =>
            new Date(a.tr_posted_date) < new Date(b.tr_posted_date) ? -1 : 1
          )
          .map((data: ObjectLiteral) => {
            return {
              tr_posted_date: data.tr_posted_date ? intl.formatDate(data.tr_posted_date, { timeZone: 'UTC' }) : '',
              sum_listed: data.sum_listed,
              sum_collected: data.sum_collected,
              sum_commission: data.sum_commission
            }
          })
      : undefined

  return store.meta.request.loading ? (
    <Spinner />
  ) : (dataSource() as any)?.length > 0 ? (
    // https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}>
        <ResponsiveContainer>
          <LineChart data={dataSource()}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="tr_posted_date" />
            <YAxis
              tickFormatter={(value) => intl.formatNumber(value ?? 0, getCurrencyFormat({ notation: 'compact' }))}
              width={40}
            />
            <Tooltip
              formatter={(value: any, name: any, item: any) => {
                switch (item.dataKey) {
                  case 'sum_listed':
                  case 'sum_collected':
                  case 'sum_commission': {
                    return intl.formatNumber(value, getCurrencyFormat())
                  }
                  default:
                    return value
                }
              }}
            />
            <Legend />
            <Line dataKey={'sum_listed'} name={'Listed'} stroke={'#F89939'} type={'monotone'} />
            <Line dataKey={'sum_collected'} name={'Collected'} stroke={'#1A3373'} type={'monotone'} />
            <Line dataKey={'sum_commission'} name={'Commission'} stroke={'#2BA3BB'} type={'monotone'} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  ) : (
    <div>No Data</div>
  )
})

export default CollectionsHistory

import { Row } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import BarLoader from 'react-spinners/BarLoader'
import ScaleLoader from 'react-spinners/ScaleLoader'

interface SpinnerProps {
  active?: boolean
  height?: number
  type?: 'bar' | 'scale'
}

const Spinner: React.FC<SpinnerProps> = observer((props: SpinnerProps) => {
  const { active = true, height, type = 'scale' } = props

  const renderSpinner = () => {
    switch (type) {
      case 'bar': {
        return <BarLoader height={height ?? 4} color={'#BDE7EF'} loading={active} />
      }
      case 'scale': {
        return <ScaleLoader height={height ?? 35} color={'#BDE7EF'} loading={active} />
      }
    }
  }

  return (
    <React.Fragment>
      <div style={{ height: '100%', width: '100%' }}>
        <Row align={'middle'} justify={'center'} style={{ height: '100%', width: '100%' }}>
          {renderSpinner()}
        </Row>
      </div>
    </React.Fragment>
  )
})

export default Spinner

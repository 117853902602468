import jsonwebtoken from 'jsonwebtoken';
import { castToSnapshot, flow, Instance, types } from 'mobx-state-tree';
import moment from 'moment';
import { ObjectLiteral } from '../../../shared/interfaces/object';
import { actionFailure, actionStart, actionSuccess, FlowState, FlowStateEnum } from '../../../shared/stores';
import { MetaModel, RequestModel, ResponseModel } from '../../../shared/stores/meta';
import { cookies } from '../../../shared/utils/cookie';
import { NxOperatorModel } from '../../collect/stores/nx-operator';
import { NxUserStore } from '../../collect/stores/nx-user';
import { AuthApiService } from '../services/api';

const AuthUserModel = types.model('AuthUserModel', {
  nx_operator: types.maybe(NxOperatorModel),
  scope: types.maybe(types.string),
});

export interface AuthUserModelInstance extends Instance<typeof AuthUserModel> {}

export const AuthMetaModel = types
  .compose(
    types
      .model({
        authenticated: types.boolean,
        accessToken: types.maybe(types.string),
        refreshToken: types.maybe(types.string),
      })
      .actions(self => {
        const setAuthenticated = (authenticated: boolean) => {
          self.authenticated = authenticated;
        };

        const setAccessToken = (token: string) => {
          self.accessToken = token;
        };

        const setRefreshToken = (token: string) => {
          self.refreshToken = token;
        };

        return {
          setAuthenticated,
          setAccessToken,
          setRefreshToken,
        };
      }),
    MetaModel
  )
  .named('AuthMetaModel');

export interface AuthMetaModelInstance extends Instance<typeof AuthMetaModel> {}

export const AuthStore = types
  .model('AuthStore', {
    state: FlowStateEnum,
    payload: types.maybe(AuthUserModel),
    error: types.maybe(types.string),
    meta: AuthMetaModel,
  })
  .actions(self => {
    const api_login = flow(function* (op_id: string, op_password: string) {
      actionStart(self);

      try {
        const response = yield AuthApiService.login(op_id, op_password);
        const { result } = response.data;

        if (![5, 3, 1].includes(parseInt(result?.nx_operator?.op_function))) {
          let error = new Error('Your user level is not permitted. Please contact your system administrator');
          actionFailure(self, error);
          throw error;
        }

        actionSuccess(self);
        saveObject(result);

        yield NxUserStore.api_getCompanyDetails();

        return response;
      } catch (error) {
        actionFailure(self, error);
        throw error;
      }
    });

    const api_getProfile = flow(function* () {
      actionStart(self);

      try {
        const response = yield AuthApiService.getProfile();
        const { result } = response.data;

        if (![5, 3, 1].includes(parseInt(result?.nx_operator?.op_function))) {
          let error = new Error('Your user level is not permitted. Please contact your system administrator');
          actionFailure(self, error);
          throw error;
        }

        actionSuccess(self);
        saveObject(result);

        return response;
      } catch (error) {
        actionFailure(self, error);
        throw error;
      }
    });

    const api_refreshToken = flow(function* () {
      actionStart(self);

      try {
        const response = yield AuthApiService.refreshToken(self.meta.refreshToken);
        const { result } = response.data;

        actionSuccess(self);
        saveObject(result);

        return response;
      } catch (error) {
        actionFailure(self, error);
        throw error;
      }
    });

    const api_logout = flow(function* () {
      actionStart(self);

      try {
        const response = yield AuthApiService.logout();

        actionSuccess(self);
        cookies.remove('x-collect-access', { path: '/' });
        cookies.remove('x-collect-refresh', { path: '/' });
        window.location.reload();

        return response;
      } catch (error) {
        actionFailure(self, error);
        throw error;
      }
    });

    const saveObject = (data: ObjectLiteral) => {
      self.payload = data
        ? AuthUserModel.create({
            nx_operator: NxOperatorModel.create({
              op_rowid: data.nx_operator?.op_rowid,
              op_id: data.nx_operator?.op_id,
              op_level: data.nx_operator?.op_level,
              op_function: data.nx_operator?.op_function,
              op_name: data.nx_operator?.op_name,
            }),
            scope: data.scope,
          })
        : undefined;

      self.meta.authenticated = data?.nx_operator ? true : false;
      if (data?.access_token) {
        self.meta.accessToken = data.access_token;
      }

      if (data?.refresh_token) {
        self.meta.refreshToken = data.refresh_token;
      }

      const accessDecoded = jsonwebtoken.decode(data?.access_token);
      const refreshDecoded = jsonwebtoken.decode(data?.refresh_token);
      if (accessDecoded && refreshDecoded) {
        const expiresAt = moment((accessDecoded as ObjectLiteral).exp * 1000).toDate();
        cookies.set('x-collect-access', data?.access_token, { expires: expiresAt, path: '/' });
        cookies.set('x-collect-refresh', data?.refresh_token, { expires: expiresAt, path: '/' });
      }
    };

    return {
      api_login,
      api_getProfile,
      api_refreshToken,
      api_logout,
      saveObject,
    };
  })
  .views(self => ({
    get isClient(): boolean {
      let retVal = false;
      const opFunction = self.payload?.nx_operator?.op_function;
      if (opFunction) retVal = [5].includes(opFunction);

      return retVal;
    },
    get isManager(): boolean {
      let retVal = false;
      const opFunction = self.payload?.nx_operator?.op_function;
      if (opFunction) retVal = [1].includes(opFunction);

      return retVal;
    },
    get isOperator(): boolean {
      let retVal = false;
      const opFunction = self.payload?.nx_operator?.op_function;
      if (opFunction) retVal = [3].includes(opFunction);

      return retVal;
    },
  }))
  .create({
    state: FlowState.IDLE,
    meta: castToSnapshot(
      AuthMetaModel.create({
        authenticated: false,
        request: castToSnapshot(
          RequestModel.create({
            loading: false,
          })
        ),
        response: ResponseModel.create(),
      })
    ),
  });

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import Cookies from 'universal-cookie'
import { ObjectLiteral } from '../../../../shared/interfaces/object'
import { AuthStore } from '../../../auth/stores'

const getAccessToken = () => {
  return AuthStore.meta.accessToken ? `Bearer ${AuthStore.meta.accessToken}` : undefined
}

const getDatabaseFromCookie = () => {
  const cookie = new Cookies()
  const cookieValue = cookie.get('x-collect-database')

  return cookieValue && cookieValue !== undefined ? cookieValue : undefined
}

export const getApiUrl = () => {
  const runtimeConfig = (window as ObjectLiteral).runtimeConfig
  return runtimeConfig.apiUrl
}

export const CollectApiClient = (config: AxiosRequestConfig, isMultipart: boolean = false) => {
  const onSuccess = (response: AxiosResponse) => {
    return response
  }

  const onFailure = (error: AxiosError) => {
    if (error.response) {
      console.error(`Status: ${error.response.status}`)
      console.error(`Data: ${JSON.stringify(error.response.data)}`)
      console.error(`Headers: ${JSON.stringify(error.response.headers)}`)
    } else {
      console.error(`Error Message: ${error.message}`)
    }

    throw error
  }

  const client = axios.create({
    baseURL: getApiUrl(),
    headers: config.headers
  })

  client.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.Authorization = getAccessToken()

    if (getDatabaseFromCookie()) {
      config.headers['x-collect-database'] = getDatabaseFromCookie()
    }

    if (isMultipart) config.headers['Content-Type'] = 'multipart/form-data'
    return config
  })

  return client(config).then(onSuccess).catch(onFailure)
}

import { RequestParams } from '../../../../shared/interfaces/request'
import { createRequestUrl } from '../../../../shared/utils/request'
import { CollectApiClient } from '../../../collect/services/api/client'

const getPicklist = (pl_uuid: string, params?: RequestParams) => {
  return CollectApiClient({
    method: 'GET',
    url: createRequestUrl(`/api/v1/dashboard/picklists/collect/${pl_uuid}`, params)
  })
}

export const CollectPicklistApiService = {
  getPicklist
}

import { observer } from 'mobx-react'
import React from 'react'
import { Route } from 'react-router-dom'
import { AuthRoute } from './components/AuthRoute'
import Login from './components/Login'
import Logout from './components/Logout'
import { AuthStore } from './stores'

const AuthContainer: React.FC = observer(() => {
  return (
    <React.Fragment>
      <Route path={'/auth/login'} component={Login} />
      <AuthRoute authenticated={AuthStore.meta.authenticated} path={'/auth/logout'} component={Logout} />
    </React.Fragment>
  )
})

export default AuthContainer

import { DownOutlined } from '@ant-design/icons'
import { Button, Col, Popover, Radio, Row, Space, Typography } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { observer } from 'mobx-react'
import React from 'react'
import { FilterBarStoreInstance } from '../../stores'

interface RadioFilterProps {
  filterBarStore: FilterBarStoreInstance
  label: string
  name: string
  options: { label: string; value: string }[]
}

const RadioFilter: React.FC<RadioFilterProps> = observer((props: RadioFilterProps) => {
  const { Text } = Typography

  const filterValuesExist = props.filterBarStore.filters.get(`${props.name}__eq`)?.value

  return (
    <Popover
      content={
        <Space direction={'vertical'} size={'small'}>
          <Row>
            <Col flex={1}>
              <Text strong>{props.label}</Text>
            </Col>
          </Row>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => {
              props.filterBarStore.addFilter({ field: props.name, operation: 'eq', value: e.target.value })
            }}
            value={filterValuesExist}
          >
            {props.options.map((option) => {
              return (
                <Radio
                  key={option.value}
                  value={option.value}
                  style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                >
                  <Text ellipsis>{option.label}</Text>
                </Radio>
              )
            })}
          </Radio.Group>
        </Space>
      }
      placement={'bottomLeft'}
      trigger={'click'}
    >
      <Button data-cy={`filter-bar-filters-radio-${props.name}`} type={filterValuesExist ? 'primary' : 'default'}>
        <span>
          <Text style={{ color: filterValuesExist ? '#F2F2F2' : '#8C8C8C' }}>{props.label}: </Text>
          <Text strong style={{ color: filterValuesExist ? '#F2F2F2' : '#000' }}>
            {filterValuesExist ? '1 Value' : 'All'}
          </Text>
        </span>
        <DownOutlined />
      </Button>
    </Popover>
  )
})

export default RadioFilter

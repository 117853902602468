import { Space } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Helmet } from 'react-helmet-async'
import FilterBar from '../../../../shared/components/FilterBar'
import CollectPageHeader from '../../../../shared/components/PageHeader'
import { createChartStore } from '../../stores/chart'
import ThreeMonthCollectionSummary from '../charts/operator-performance/ThreeMonthCollectionSummary'
import TodayContactTypeBreakdown from '../charts/operator-performance/TodayContactTypeBreakdown'
import TodayPaymentTotals from '../charts/operator-performance/TodayPaymentTotals'
import Widget from '../shared/Widget'

const RGL = WidthProvider(Responsive)

const OperatorPerformanceDashboard: React.FC = observer(() => {
  const [threeMonthCollectionSummaryStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: '/api/v1/dashboard/dashboard/manager/three-month-collection-summary' } }
    })
  })

  const [todayContactTypeBreakdownStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: '/api/v1/dashboard/dashboard/manager/today-contact-type-breakdown' } }
    })
  })

  const [todayPaymentTotalsStore] = useState(() => {
    return createChartStore({
      meta: { request: { loading: false, url: '/api/v1/dashboard/dashboard/manager/today-payment-totals' } }
    })
  })

  const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }
  const cols = { lg: 6, md: 6, sm: 6, xs: 3, xxs: 3 }
  const layouts = {
    lg: [
      { i: 'three-month-collection-summary', x: 0, y: 0, w: 6, h: 2, static: true },
      { i: 'today-contact-type-breakdown', x: 0, y: 2, w: 3, h: 2, static: true },
      { i: 'today-payment-totals', x: 3, y: 2, w: 3, h: 2, static: true }
    ],
    xs: [
      { i: 'three-month-collection-summary', x: 0, y: 0, w: 3, h: 2, static: true },
      { i: 'today-contact-type-breakdown', x: 0, y: 2, w: 3, h: 2, static: true },
      { i: 'today-payment-totals', x: 0, y: 4, w: 3, h: 2, static: true }
    ]
  }

  return (
    <Space direction={'vertical'} size={'small'} style={{ width: '100%' }}>
      <Helmet title={'Operator Performance Dashboard'} />
      <CollectPageHeader title={'Operator Performance Dashboard'} />
      <FilterBar
        filters={[
          {
            type: 'dynamic-picklist',
            label: 'Operator',
            name: 'op_id',
            storeProps: {
              scope: 'nx_operator',
              key: 'op_id',
              value: 'op_name',
              extra: 'op_id',
              filters: {
                op_switches: {
                  field: 'op_switches',
                  operation: 'nbits',
                  value: '16'
                }
              }
            }
          }
        ]}
        showRefresh={true}
        stores={[threeMonthCollectionSummaryStore, todayContactTypeBreakdownStore, todayPaymentTotalsStore]}
      />
      <RGL breakpoints={breakpoints} cols={cols} containerPadding={[0, 0]} layouts={layouts} rowHeight={100}>
        <div key={'three-month-collection-summary'}>
          <Widget title={'Three Month Collection Summary by Working Day'}>
            <ThreeMonthCollectionSummary store={threeMonthCollectionSummaryStore} />
          </Widget>
        </div>
        <div key={'today-contact-type-breakdown'}>
          <Widget title={`Today's Contact Type Breakdown `}>
            <TodayContactTypeBreakdown store={todayContactTypeBreakdownStore} />
          </Widget>
        </div>
        <div key={'today-payment-totals'}>
          <Widget title={`Today's Payment Totals`}>
            <TodayPaymentTotals store={todayPaymentTotalsStore} />
          </Widget>
        </div>
      </RGL>
    </Space>
  )
})

export default OperatorPerformanceDashboard

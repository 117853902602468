import { Instance, types } from 'mobx-state-tree'
import { RequestFilterModel, RequestFilterModelInstance } from '../../../../shared/stores/meta'
import { RequestParams } from '../../../interfaces/request'

const FilterBarStore = types
  .model('FilterBarStore', {
    filters: types.map(RequestFilterModel)
  })
  .actions((self) => {
    const addFilter = (filter: RequestFilterModelInstance) => {
      self.filters.set(`${filter.field}__${filter.operation}`, filter)
    }

    return {
      addFilter
    }
  })
  .views((self) => ({
    get getFilterParams(): RequestParams {
      const filterString = Array.from(self.filters.values())
        .filter((f) => f.value)
        .map((f) => {
          return f.operation === 'in'
            ? `${f.field}__${f.operation}=(${f.value})`
            : `${f.field}__${f.operation}=${f.value}`
        })
        .join(',')

      return {
        ...(filterString.length > 0 && { filter: filterString })
      }
    }
  }))

export interface FilterBarStoreInstance extends Instance<typeof FilterBarStore> {}

export const createFilterBarStore = () => {
  return FilterBarStore.create()
}

import useVisible from '@21kb/react-page-visible-hook'
import { Col, Row, Space } from 'antd'
import { reaction } from 'mobx'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import querystring from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { RequestFilterModelInstance } from '../../stores/meta'
import { filterStringToParts } from '../../utils/request'
import CheckboxFilter from './components/filters/Checkbox'
import DateFilter from './components/filters/Date'
import DateRangeFilter from './components/filters/DateRange'
import DynamicPicklistFilter from './components/filters/DynamicPicklist'
import PicklistFilter from './components/filters/Picklist'
import RadioFilter from './components/filters/Radio'
import RefreshButton from './components/RefreshButton'
import { createFilterBarStore, FilterBarStoreInstance } from './stores'

type FilterType = 'checkbox' | 'date' | 'date-range' | 'dynamic-picklist' | 'picklist' | 'radio'

interface CheckboxFilter {
  type: Extract<FilterType, 'checkbox'>
  label: string
  name: string
  options: { label: string; value: string }[]
}

interface DateFilter {
  type: Extract<FilterType, 'date'>
  label: string
  name: string
  pickerProps: {
    picker: 'month'
  }
}

interface DateRangeFilter {
  type: Extract<FilterType, 'date-range'>
  label: string
  name: string | [string, string]
}

interface DynamicPicklistFilter {
  type: Extract<FilterType, 'dynamic-picklist'>
  label: string
  name: string
  storeProps: {
    scope: string
    key: string
    value: string
    extra?: string
    filters?: { [key: string]: RequestFilterModelInstance }
  }
}

interface PicklistFilter {
  type: Extract<FilterType, 'picklist'>
  label: string
  name: string
  pl_uuid: string
}

interface RadioFilter {
  type: Extract<FilterType, 'radio'>
  label: string
  name: string
  options: { label: string; value: string }[]
}

export type FilterBarFilter =
  | CheckboxFilter
  | DateFilter
  | DateRangeFilter
  | DynamicPicklistFilter
  | PicklistFilter
  | RadioFilter

interface FilterBarProps {
  filterBarStore?: FilterBarStoreInstance
  filters?: FilterBarFilter[]
  showRefresh?: boolean
  stores?: any[]
}

/**
 * Filter Bar
 *
 * The Filter Bar is passed an array of stores which are updated when filters change
 *
 * Filters are provided as an array of objects.  Filter values are gathered in the `filters` useState variable, and when
 * `debouncedFilters` is updated, an API call is made to refresh data.
 *
 * This prevents additional rerenders of passing filters to the stores directly
 */
const FilterBar: React.FC<FilterBarProps> = observer((props: FilterBarProps) => {
  const filterBarStore = useMemo(() => props.filterBarStore ?? createFilterBarStore(), [props.filterBarStore])
  const location = useLocation()

  /**
   * Each store in props.stores should fetch new data when the report filter changes.
   */
  useEffect(() => {
    const filterBarStoreCleanup = reaction(
      () => getSnapshot(filterBarStore.filters),
      (data) => {
        const filtersToApply = Object.values(data)
        props.stores?.forEach((store) => {
          if (store.list && store.object) {
            // Paginated Stores
            filtersToApply.forEach((f) => store.list.meta.request.addFilter(f))

            const currentFilters = JSON.stringify(store.list.meta.request.filters.values())
            const newFilters = JSON.stringify(filtersToApply)

            if (currentFilters !== newFilters) {
              store.list.meta.request.pagination?.setPage(1)
            }

            store.list.api_getList()
          } else {
            // Chart Stores
            filtersToApply.forEach((f) => store.meta.request.addFilter(f))
            store.api_getData()
          }
        })
      },
      { delay: 250 }
    )

    // On initial load, find filters in the store.
    props.stores?.forEach((store) => {
      if (store.list && store.object) {
        // Paginated Stores
        const filters = Object.values(getSnapshot(store.list.meta.request.filters))

        const filtersWithValues = filters.filter((f) => (f as RequestFilterModelInstance).value)

        if (filtersWithValues.length > 0) {
          // If filters exist, add them to the filterBarStore
          filtersWithValues.forEach((f) => filterBarStore.addFilter(f as RequestFilterModelInstance))
        } else {
          // If no filters exist, api_getList()
          store.list.api_getList()
        }
      } else {
        // Chart Stores
        const filters = Object.values(getSnapshot(store.meta.request.filters))

        const filtersWithValues = filters.filter((f) => (f as RequestFilterModelInstance).value)

        if (filtersWithValues.length > 0) {
          // If filters exist, add them to the filterBarStore
          filtersWithValues.forEach((f) => filterBarStore.addFilter(f as RequestFilterModelInstance))
        } else {
          // If no filters exist, api_getList()
          store.api_getData()
        }
      }
    })

    return () => {
      filterBarStoreCleanup()
    }
  }, [])

  // Map url querystring to filters
  useEffect(() => {
    const search = location.search.length > 0 ? location.search : undefined
    if (search) {
      const filter = querystring.parse(search).filter as string
      const filterStrings = filter ? filterStringToParts(filter) : []
      filterStrings.forEach((filter) => {
        const parts = filter.split(/__|=/)
        if (parts.length === 3) {
          filterBarStore.addFilter({
            field: parts[0],
            operation: parts[1],
            value: parts[2].replace('(', '').replace(')', '')
          })
        }
      })
    }
  }, [location.search])

  const renderReportFilters = props.filters?.map((f) => {
    switch (f.type) {
      case 'checkbox': {
        const { label, name, options } = f
        return <CheckboxFilter key={name} filterBarStore={filterBarStore} label={label} name={name} options={options} />
      }
      case 'date': {
        const { label, name, pickerProps } = f
        return (
          <DateFilter key={name} filterBarStore={filterBarStore} label={label} name={name} pickerProps={pickerProps} />
        )
      }
      case 'date-range': {
        const { label, name } = f
        return (
          <DateRangeFilter
            key={Array.isArray(name) ? name.join('-') : name}
            filterBarStore={filterBarStore}
            label={label}
            name={name}
          />
        )
      }
      case 'dynamic-picklist': {
        const { label, name, storeProps } = f
        return (
          <DynamicPicklistFilter
            key={name}
            filterBarStore={filterBarStore}
            label={label}
            name={name}
            storeProps={storeProps}
          />
        )
      }
      case 'picklist': {
        const { label, name, pl_uuid } = f
        return <PicklistFilter key={name} filterBarStore={filterBarStore} label={label} name={name} pl_uuid={pl_uuid} />
      }
      case 'radio': {
        const { label, name, options } = f
        return <RadioFilter key={name} filterBarStore={filterBarStore} label={label} name={name} options={options} />
      }
    }
  })

  // ------------------------------------------------------------------------
  const { hidden: pageVisible } = useVisible()
  const [refreshAfter, setRefreshAfter] = useState<number>(-1)

  useEffect(() => {
    if (!pageVisible) {
      if (refreshAfter > -1) {
        const refreshTimer = setInterval(() => {
          props.stores?.forEach((store) => {
            if (store.list && store.object) {
              if (!store.list.meta.request.loading) store.list.api_getList()
            } else {
              if (!store.meta.request.loading) store.api_getData()
            }
          })
        }, refreshAfter * 1000)
        return () => clearInterval(refreshTimer)
      }
    }
  }, [refreshAfter, pageVisible])
  // ------------------------------------------------------------------------

  return (
    <Row data-cy={'filter-bar'} gutter={16}>
      <Col flex={1} style={{ overflowX: 'auto' }}>
        <Space data-cy={'filter-bar-filters'} direction={'horizontal'} size={'small'}>
          {renderReportFilters}
        </Space>
      </Col>
      <Col flex={0}>
        {props.showRefresh && <RefreshButton setRefreshAfter={setRefreshAfter} refreshAfter={refreshAfter} />}
      </Col>
    </Row>
  )
})

export default FilterBar
